:root {
  --step: 0.25rem;
  /* BREAKPOINTS */
  --sm: 640px;
  --md: 768px;
  --lg: 1024px;
  --xl: 1280px;

  /* SIZE STEPS */
  --s0: 0;
  --s1px: 1px;
  --sAuto: auto;
  --s100vh: 100vh;
  --s100vw: 100vw;
  --sFull: 100%;

  /*Percents*/
  --s10p: 10%;
  --s20p: 20%;
  --s25p: 25%;
  --s30p: 30%;
  --s33p: 33.333333%;
  --s40p: 40%;
  --s50p: 50%;
  --s60p: 60%;
  --s66p: 66.666667%;
  --s70p: 70%;
  --s75p: 75%;
  --s80p: 80%;
  --s90p: 90%;

  /*Steps*/
  --s4: 0.25rem; /*4px*/
  --s8: 0.5rem; /*8px*/
  --s12: 0.75rem;
  --s16: 1rem;
  --s20: 1.25rem;
  --s24: 1.5rem;
  --s28: 1.75rem;
  --s32: 2rem;
  --s36: 2.25rem;
  --s40: 2.5rem;
  --s44: 2.75rem;
  --s48: 3rem;
  --s52: 3.25rem;
  --s56: 3.5rem;
  --s60: 3.75rem;
  --s64: 4rem;
  --s68: 4.25rem;
  --s72: 4.5rem;
  --s76: 4.75rem;
  --s80: 5rem;
  --s84: 5.25rem;
  --s88: 5.5rem;
  --s92: 5.75rem;
  --s96: 6rem;
  --s100: 6.25rem; /*100px*/
  --s128: 8rem;
  --s160: 10rem;
}
