@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700&display=swap);
@font-face {
  font-family: 'Inter';
  src: url(/static/media/Inter-Regular.515cae74.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Inter';
  src: url(/static/media/Inter-Bold.91e5aee8.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Inter';
  src: url(/static/media/Inter-Light.6ffbefc6.ttf) format('truetype');
  font-weight: lighter;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Merriweather';
  src: url(/static/media/Merriweather-Regular.c97a9fc2.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Merriweather';
  src: url(/static/media/Merriweather-Bold.9ccb885c.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  /***NEW PALLETE SCHEME***/
  --black: #000000;
  --white: #ffffff;
  /*PRIMARY COLORS*/
  /*Grey*/
  --grey0: #f7f7fa;
  --grey5: #ededf0;
  --grey10: #e1e1e3;
  --grey20: #d2d2d6;
  --grey30: #b4b4bb;
  --grey40: #9696a0;
  --grey50: #787885;
  --grey60: #5a5b6a;
  --grey70: #4a4b57;
  --grey80: #3a3a44;
  --grey90: #292a31;
  --grey100: #19191d;
  /*Blue*/

  --blue0: #f5f8ff;
  --blue5: #ebf2ff;
  --blue10: #d8e6ff;
  --blue20: #c4daff;
  --blue30: #9dc2ff;
  --blue40: #76a9ff;
  --blue50: #4f91ff;
  --blue60: #2979ff;
  --blue70: #2264d1;
  --blue80: #1b4ea3;
  --blue90: #133774;
  --blue100: #0c2146;
  /*Green*/
  --green0: #f5faf5;
  --green5: #ecf7ed;
  --green10: #dceddd;
  --green20: #cbe5cc;
  --green30: #a9d3ab;
  --green40: #87c289;
  --green50: #65b168;
  --green60: #43a047;
  --green70: #37833b;
  --green80: #2b662e;
  --green90: #1f4921;
  --green100: #132c14;
  /*Yellow*/
  --yellow0: #fff8eb;
  --yellow5: #ffefd1;
  --yellow10: #ffe5b3;
  --yellow20: #ffd98f;
  --yellow30: #f5ce84;
  --yellow40: #ebbf67;
  --yellow50: #e5ae40;
  --yellow60: #d6981b;
  --yellow70: #b88217;
  --yellow80: #8f6512;
  --yellow90: #66480d;
  --yellow100: #463209;
  /*Red*/
  --red0: #fef2f1;
  --red5: #fee8e7;
  --red10: #fddcda;
  --red20: #fccbc8;
  --red30: #faa9a3;
  --red40: #f8877f;
  --red50: #f6655a;
  --red60: #f44336;
  --red70: #c8372d;
  --red80: #9c2b23;
  --red90: #6f1f19;
  --red100: #43130f;
  /*SECONDARY COLORS*/
  /*Indigo*/
  --indigo0: #f7f8fc;
  --indigo5: #eff0fa;
  --indigo10: #e1e4f3;
  --indigo20: #d2d6ed;
  --indigo30: #b4bbe2;
  --indigo40: #97a0d6;
  --indigo50: #7985cb;
  --indigo60: #5c6bc0;
  --indigo70: #4c589e;
  --indigo80: #3b457b;
  --indigo90: #2a3158;
  --indigo100: #1a1e35;
  /*Teal*/
  --teal0: #f0fafa;
  --teal5: #e5f5f5;
  --teal10: #d1ebec;
  --teal20: #bbe2e2;
  --teal30: #8dcfcf;
  --teal40: #60bcbc;
  --teal50: #33a9a9;
  --teal60: #069697;
  --teal70: #057b7c;
  --teal80: #046061;
  --teal90: #034545;
  --teal100: #02292a;
  /*Orange*/
  --orange0: #fff6ed;
  --orange5: #ffefde;
  --orange10: #fee7cd;
  --orange20: #fddcb9;
  --orange30: #fdc68b;
  --orange40: #fcaf5c;
  --orange50: #fb982e;
  --orange60: #fb8200;
  --orange70: #ce6b00;
  --orange80: #a05300;
  --orange90: #733c00;
  --orange100: #452400;
  /*Pink*/
  --pink0: #fff7fa;
  --pink5: #fdedf2;
  --pink10: #fbdce6;
  --pink20: #f9cada;
  --pink30: #f6a8c2;
  --pink40: #f285aa;
  --pink50: #ef6292;
  --pink60: #ec407a;
  --pink70: #c23564;
  --pink80: #97294e;
  --pink90: #6c1e38;
  --pink100: #411222;

  /* SHADOWS */
  --greyShadow2dp: 0px 2px 4px rgba(90, 91, 106, 0.24),
    0px 1px 2px rgba(58, 58, 68, 0.24);
  --greyShadow4dp: 0px 4px 8px rgba(90, 91, 106, 0.2),
    0px 2px 4px rgba(58, 58, 68, 0.2);
  --greyShadow8dp: 0px 8px 16px rgba(90, 91, 106, 0.16),
    0px 4px 8px rgba(58, 58, 68, 0.16);
  --greyShadow16dp: 0px 16px 32px rgba(90, 91, 106, 0.12),
    0px 8px 16px rgba(58, 58, 68, 0.12);
  --greyShadow24dp: 0px 24px 48px rgba(90, 91, 106, 0.08),
    0px 12px 24px rgba(58, 58, 68, 0.08);

  --blueShadow2dp: 0px 2px 4px rgba(41, 121, 255, 0.24),
    0px 1px 2px rgba(27, 78, 163, 0.24);
  --blueShadow4dp: 0px 4px 8px rgba(41, 121, 255, 0.2),
    0px 2px 4px rgba(27, 78, 163, 0.2);
  --blueShadow8dp: 0px 8px 16px rgba(41, 121, 255, 0.16),
    0px 4px 8px rgba(27, 78, 163, 0.16);
  --blueShadow16dp: 0px 16px 32px rgba(41, 121, 255, 0.12),
    0px 8px 16px rgba(27, 78, 163, 0.12);
  --blueShadow24dp: 0px 24px 48px rgba(41, 121, 255, 0.08),
    0px 12px 24px rgba(27, 78, 163, 0.08);

  --redShadow2dp: 0px 2px 4px rgba(252, 203, 200, 0.24),
    0px 1px 2px rgba(156, 43, 35, 0.24);
  --redShadow4dp: 0px 4px 8px rgba(244, 67, 54, 0.2),
    0px 2px 4px rgba(156, 43, 35, 0.2);
  --redShadow8dp: 0px 8px 16px rgba(244, 67, 54, 0.16),
    0px 4px 8px rgba(156, 43, 35, 0.16);
  --redShadow16dp: 0px 16px 32px rgba(244, 67, 54, 0.12),
    0px 8px 16px rgba(156, 43, 35, 0.12);
  --redShadow24dp: 0px 24px 48px rgba(244, 67, 54, 0.08),
    0px 12px 24px rgba(156, 43, 35, 0.08);

  --greenShadow2dp: 0px 2px 4px rgba(67, 160, 71, 0.24),
    0px 1px 2px rgba(43, 102, 46, 0.24);
  --greenShadow4dp: 0px 4px 8px rgba(67, 160, 71, 0.2),
    0px 2px 4px rgba(43, 102, 46, 0.2);
  --greenShadow8dp: 0px 8px 16px rgba(67, 160, 71, 0.16),
    0px 4px 8px rgba(43, 102, 46, 0.16);
  --greenShadow16dp: 0px 16px 32px rgba(67, 160, 71, 0.12),
    0px 8px 16px rgba(43, 102, 46, 0.12);
  --greenShadow24dp: 0px 24px 48px rgba(67, 160, 71, 0.08),
    0px 12px 24px rgba(43, 102, 46, 0.08);

  --yellowShadow2dp: 0px 2px 4px rgba(214, 152, 27, 0.24),
    0px 1px 2px rgba(143, 101, 18, 0.24);
  --yellowShadow4dp: 0px 4px 8px rgba(214, 152, 27, 0.2),
    0px 2px 4px rgba(143, 101, 18, 0.2);
  --yellowShadow8dp: 0px 8px 16px rgba(214, 152, 27, 0.16),
    0px 4px 8px rgba(143, 101, 18, 0.16);
  --yellowShadow16dp: 0px 16px 32px rgba(214, 152, 27, 0.12),
    0px 8px 16px rgba(143, 101, 18, 0.12);
  --yellowShadow24dp: 0px 24px 48px rgba(214, 152, 27, 0.08),
    0px 12px 24px rgba(143, 101, 18, 0.08);

  --indigoShadow2dp: 0px 2px 4px rgba(92, 107, 192, 0.24),
    0px 1px 2px rgba(59, 69, 123, 0.24);
  --indigoShadow4dp: 0px 4px 8px rgba(92, 107, 192, 0.2),
    0px 2px 4px rgba(59, 69, 123, 0.2);
  --indigoShadow8dp: 0px 8px 16px rgba(92, 107, 192, 0.16),
    0px 4px 8px rgba(59, 69, 123, 0.16);
  --indigoShadow16dp: 0px 16px 32px rgba(92, 107, 192, 0.12),
    0px 8px 16px rgba(59, 69, 123, 0.12);
  --indigoShadow24dp: 0px 24px 48px rgba(92, 107, 192, 0.08),
    0px 12px 24px rgba(59, 69, 123, 0.08);

  --tealShadow2dp: 0px 2px 4px rgba(6, 150, 151, 0.24),
    0px 1px 2px rgba(4, 96, 97, 0.24);
  --tealShadow4dp: 0px 4px 8px rgba(6, 150, 151, 0.2),
    0px 2px 4px rgba(4, 96, 97, 0.2);
  --tealShadow8dp: 0px 8px 16px rgba(6, 150, 151, 0.16),
    0px 4px 8px rgba(4, 96, 97, 0.16);
  --tealShadow16dp: 0px 16px 32px rgba(6, 150, 151, 0.12),
    0px 8px 16px rgba(4, 96, 97, 0.12);
  --tealShadow24dp: 0px 24px 48px rgba(6, 150, 151, 0.08),
    0px 12px 24px rgba(4, 96, 97, 0.08);

  --orangeShadow2dp: 0px 2px 4px rgba(251, 130, 0, 0.24),
    0px 1px 2px rgba(160, 83, 0, 0.24);
  --orangeShadow4dp: 0px 4px 8px rgba(251, 130, 0, 0.2),
    0px 2px 4px rgba(160, 83, 0, 0.2);
  --orangeShadow8dp: 0px 8px 16px rgba(251, 130, 0, 0.16),
    0px 4px 8px rgba(160, 83, 0, 0.16);
  --orangeShadow16dp: 0px 16px 32px rgba(251, 130, 0, 0.12),
    0px 8px 16px rgba(160, 83, 0, 0.12);
  --orangeShadow24dp: 0px 24px 48px rgba(251, 130, 0, 0.08),
    0px 12px 24px rgba(160, 83, 0, 0.08);

  --pinkShadow2dp: 0px 2px 4px rgba(236, 64, 122, 0.24),
    0px 1px 2px rgba(151, 41, 78, 0.24);
  --pinkShadow4dp: 0px 4px 8px rgba(236, 64, 122, 0.2),
    0px 2px 4px rgba(151, 41, 78, 0.2);
  --pinkShadow8dp: 0px 8px 16px rgba(236, 64, 122, 0.16),
    0px 4px 8px rgba(151, 41, 78, 0.16);
  --pinkShadow16dp: 0px 16px 32px rgba(236, 64, 122, 0.12),
    0px 8px 16px rgba(151, 41, 78, 0.12);
  --pinkShadow24dp: 0px 24px 48px rgba(236, 64, 122, 0.08),
    0px 12px 24px rgba(151, 41, 78, 0.08);

  /*NEW GRADIENTS*/
  --gradBlue: var(--blue60) 0%, var(--indigo70) 100%;
  --gradBlueRev: var(--indigo70) 0%, var(--blue60) 100%;
  --gradRed: var(--red60) 0%, var(--pink70) 100%;
  --gradRedRev: var(--pink70) 0%, var(--red60) 100%;
  --gradGreen: var(--green60) 0%, var(--teal70) 100%;
  --gradGreenRev: var(--teal70) 0%, var(--green60) 100%;
  --gradYellow: var(--yellow60) 0%, var(--orange70) 100%;
  --gradYellowRev: var(--orange70) 0%, var(--yellow60) 100%;
  --gradGrey: var(--grey40) 0%, var(--grey70) 100%;
  --gradGreyRev: var(--grey70) 0%, var(--grey40) 100%;

  --gradIndigo: var(--indigo60) 0%, var(--blue70) 100%;
  --gradIndigoRev: var(--blue70) 0%, var(--indigo60) 100%;
  --gradPink: var(--pink60) 0%, var(--red70) 100%;
  --gradPinkRev: var(--red70) 0%, var(--pink60) 100%;
  --gradTeal: var(--teal60) 0%, var(--green70) 100%;
  --gradTealRev: var(--green70) 0%, var(--teal60) 100%;
  --gradOrange: var(--orange60) 0%, var(--yellow70) 100%;
  --gradOrangeRev: var(--yellow70) 0%, var(--orange60) 100%;

  /*Ligth*/
  --gradBlueL: var(--blue10) 0%, var(--indigo30) 100%;
  --gradBlueRevL: var(--indigo30) 0%, var(--blue10) 100%;
  --gradRedL: var(--red10) 0%, var(--pink30) 100%;
  --gradRedRevL: var(--pink30) 0%, var(--red10) 100%;
  --gradGreenL: var(--green10) 0%, var(--teal30) 100%;
  --gradGreenRevL: var(--teal30) 0%, var(--green10) 100%;
  --gradYellowL: var(--yellow10) 0%, var(--orange30) 100%;
  --gradYellowRevL: var(--orange30) 0%, var(--yellow10) 100%;
  --gradGreyL: var(--grey10) 0%, var(--grey40) 100%;
  --gradGreyRevL: var(--grey40) 0%, var(--grey10) 100%;
}

html,
body {
  font-size: 100%;
  /*font-size: var(--font-size-Body1);*/
  font-family: var(--font-primary);
  font-style: normal;
  line-height: normal;
  line-height: initial;
  letter-spacing: var(--letter-spacing-Body1);
  font-weight: var(--font-weight-Body1);
  color: var(--grey100);
}
button {
  font-family: var(--font-primary);
}

/*Remove blue background on click*/
input,
textarea,
button,
select,
label,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
/**/
* {
  -webkit-tap-highlight-color: transparent;
}

*:focus {
  outline: none;
  outline-offset: 0;
}

.bp3-control input:focus ~ .bp3-control-indicator {
  outline: none;
  outline-offset: 0;
}

.bp3-button:not([class*="bp3-intent-"]) {
  box-shadow: none;
  background-color: unset;
  background-image: none;
  color: unset;
}

.bp3-button:not([class*="bp3-intent-"]):hover {
  box-shadow: none;
  background-clip: unset;
  background-color: unset;
}

.bp3-button .bp3-icon {
  color: unset;
}

label.bp3-label {
  font-size: 1rem;
  font-weight: bold;
  text-align: left;
  text-align: initial;
  margin-bottom: 0.625rem;
  margin-right: 1rem;
}

@charset "UTF-8";

:root {
  --roboto: "Roboto", sans-serif;
  --quicksand: "Quicksand", sans-serif;
  --inter: "Inter", sans-serif;
  --plex: "IBM Plex Sans", sans-serif;
  --lato: "Lato", sans-serif;
  --manrope: "Manrope", sans-serif;

  /*Font sizes*/
  --font_size_h1: 6rem;
  --font_size_h2: 3.75rem;
  --font_size_h3: 3rem;
  --font_size_h4: 2.125rem;
  --font_size_h5: 1.5rem;
  --font_size_h6: 1.25rem;
  --font_size_body: 1rem;
  --font_size_body_large: 1.25rem;
  --font_size_body_small: 0.875rem;
  --font_size_button: 0.875rem;
  --font_size_caption: 0.75rem;
  --font_size_caption_small: 0.625rem;
  --font_size_overline: 0.75rem;
  --font_size_overline_small: 0.625rem;

  /*Font weight*/
  --font_weight_h1: 700;
  --font_weight_h2: 700;
  --font_weight_h3: 700;
  --font_weight_h4: 700;
  --font_weight_h5: 700;
  --font_weight_h6: 700;
  --font_weight_body: 400;
  --font_weight_body_medium: 500;
  --font_weight_button: 700;
  --font_weight_caption: 400;
  --font_weight_caption_small: 500;
  --font_weight_overline: 600;

  /*Letter spacing*/
  --letter_spacing_h1: -0.026em;
  --letter_spacing_h2: -0.025em;
  --letter_spacing_h3: -0.01em;
  --letter_spacing_h4: 0;
  --letter_spacing_h5: 0;
  --letter_spacing_h6: 0.0075em;
  --letter_spacing_body: 0.0275em;
  --letter_spacing_body_bold: 0.022em;
  --letter_spacing_body_large: 0.0075em;
  --letter_spacing_body_large_bold: 0.004em;
  --letter_spacing_body_small: 0.018em;
  --letter_spacing_button: 0.054em;
  --letter_spacing_caption: 0.033em;
  --letter_spacing_caption_small: 0.06em;
  --letter_spacing_overline: 0.0168em;
  --letter_spacing_overline_small: 0.15em;
}

.roboto {
  font-family: "Roboto", sans-serif;
  font-family: var(--roboto);
}
.quicksand {
  font-family: "Quicksand", sans-serif;
  font-family: var(--quicksand);
}
.inter {
  font-family: "Inter", sans-serif;
  font-family: var(--inter);
}
.lato {
  font-family: "Lato", sans-serif;
  font-family: var(--lato);
}
.plex {
  font-family: "IBM Plex Sans", sans-serif;
  font-family: var(--plex);
}
.monrope {
  font-family: "Manrope", sans-serif;
  font-family: var(--manrope);
}

.h1,
.H1 {
  font-weight: 700;
  font-weight: var(--font_weight_h1);
  font-size: 6rem;
  font-size: var(--font_size_h1);
  letter-spacing: -0.026em;
  letter-spacing: var(--letter_spacing_h1);
}

.h2,
.H2 {
  font-weight: 700;
  font-weight: var(--font_weight_h2);
  font-size: 3.75rem;
  font-size: var(--font_size_h2);
  letter-spacing: -0.025em;
  letter-spacing: var(--letter_spacing_h2);
}

.h3,
.H3 {
  font-weight: 700;
  font-weight: var(--font_weight_h3);
  font-size: 3rem;
  font-size: var(--font_size_h3);
  letter-spacing: -0.01em;
  letter-spacing: var(--letter_spacing_h3);
}

.h4,
.H4 {
  font-weight: 700;
  font-weight: var(--font_weight_h4);
  font-size: 2.125rem;
  font-size: var(--font_size_h4);
  letter-spacing: 0;
  letter-spacing: var(--letter_spacing_h4);
}

.h5,
.H5 {
  font-weight: 700;
  font-weight: var(--font_weight_h5);
  font-size: 1.5rem;
  font-size: var(--font_size_h5);
  letter-spacing: 0;
  letter-spacing: var(--letter_spacing_h5);
}

.h6,
.H6 {
  font-weight: 700;
  font-weight: var(--font_weight_h6);
  font-size: 1.25rem;
  font-size: var(--font_size_h6);
  letter-spacing: 0.0075em;
  letter-spacing: var(--letter_spacing_h6);
}

.body,
.BodyBig {
  font-weight: 400;
  font-weight: var(--font_weight_body);
  font-size: 1rem;
  font-size: var(--font_size_body);
  letter-spacing: 0.0275em;
  letter-spacing: var(--letter_spacing_body);
}

.button_text {
  font-weight: 700;
  font-weight: var(--font_weight_button);
  font-size: 0.875rem;
  font-size: var(--font_size_button);
  letter-spacing: 0.054em;
  letter-spacing: var(--letter_spacing_button);
}

.caption {
  font-weight: 400;
  font-weight: var(--font_weight_caption);
  font-size: 0.75rem;
  font-size: var(--font_size_caption);
  letter-spacing: 0.033em;
  letter-spacing: var(--letter_spacing_caption);
}

.overline {
  font-weight: 600;
  font-weight: var(--font_weight_overline);
  font-size: 0.75rem;
  font-size: var(--font_size_overline);
  letter-spacing: 0.0168em;
  letter-spacing: var(--letter_spacing_overline);
  text-transform: uppercase;
}

:root {
  --step: 0.25rem;
  /* BREAKPOINTS */
  --sm: 640px;
  --md: 768px;
  --lg: 1024px;
  --xl: 1280px;

  /* SIZE STEPS */
  --s0: 0;
  --s1px: 1px;
  --sAuto: auto;
  --s100vh: 100vh;
  --s100vw: 100vw;
  --sFull: 100%;

  /*Percents*/
  --s10p: 10%;
  --s20p: 20%;
  --s25p: 25%;
  --s30p: 30%;
  --s33p: 33.333333%;
  --s40p: 40%;
  --s50p: 50%;
  --s60p: 60%;
  --s66p: 66.666667%;
  --s70p: 70%;
  --s75p: 75%;
  --s80p: 80%;
  --s90p: 90%;

  /*Steps*/
  --s4: 0.25rem; /*4px*/
  --s8: 0.5rem; /*8px*/
  --s12: 0.75rem;
  --s16: 1rem;
  --s20: 1.25rem;
  --s24: 1.5rem;
  --s28: 1.75rem;
  --s32: 2rem;
  --s36: 2.25rem;
  --s40: 2.5rem;
  --s44: 2.75rem;
  --s48: 3rem;
  --s52: 3.25rem;
  --s56: 3.5rem;
  --s60: 3.75rem;
  --s64: 4rem;
  --s68: 4.25rem;
  --s72: 4.5rem;
  --s76: 4.75rem;
  --s80: 5rem;
  --s84: 5.25rem;
  --s88: 5.5rem;
  --s92: 5.75rem;
  --s96: 6rem;
  --s100: 6.25rem; /*100px*/
  --s128: 8rem;
  --s160: 10rem;
}

:root {
  /*Fonts*/
  --font-primary: var(--inter);
  --font_primary: var(--inter);

  --font-secondary: var(--inter); /** Used for captions */
  --font_secondary: var(--inter);

  --font-family-button: var(--inter);
  --font_family_button: var(--inter);
}

/**INPUT*/
._type_def__ZPeEj,
._type_dense__B3GbZ/*,
.fixed*/ {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
._type_full__2zC_b {
  width: 100%;
}
._type_def__ZPeEj[class*="input-group"] input[class*="input"] {
  border-radius: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.625rem !important;
  margin-top: 0.125rem;
  margin-bottom: 0.125rem;
}
._type_def__ZPeEj[class*="input-group"] input[class*="input"] {
  height: 2.5rem;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  font-weight: var(--font_weight_body);
}

._type_def__ZPeEj label {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_small);
  transition: all 200ms ease-in-out;
  z-index: 1;
  pointer-events: none;
}
/*Dense*/
._type_dense__B3GbZ[class*="input-group"] input[class*="input"] {
  border-radius: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.375rem !important;
  margin-top: 0.0625rem;
  margin-bottom: 0.0625rem;
}
._type_dense__B3GbZ[class*="input-group"] input[class*="input"] {
  height: 1.75rem;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body);
}
._type_dense__B3GbZ label {
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  font-weight: var(--font_weight_caption);
  transition: all 200ms ease-in-out;
  z-index: 1;
  pointer-events: none;
}

/*CLEAR BUTTON*/
._type_def__ZPeEj._type_clearButton__2SopT[class*="input-group"] span[class*="input-action"] > button {
  height: 2.5rem;
  padding: 0;
  margin: 0;
  margin-right: 0.5rem;
  margin-top: 0.125rem;
}
._type_dense__B3GbZ._type_clearButton__2SopT[class*="input-group"] span[class*="input-action"] > button {
  height: 1.75rem;
  padding: 0;
  margin: 0;
  margin-right: 0.125rem;
  margin-top: 0.0625rem;
}
._type_dense__B3GbZ._type_clearButton__2SopT[class*="input-group"]
  span[class*="input-action"]
  > button
  svg {
  width: 1rem;
  height: 1rem;
}
._type_clearButton__2SopT[class*="input-group"] span[class*="input-action"] {
  top: auto;
  top: initial;
}
/*RIGHT ICON*/
._type_def__ZPeEj._type_right-icon__2pcp4[class*="input-group"] span[class*="input-action"] {
  padding: 0;
  margin: 0;
  margin-right: 0.625rem;
  margin-top: 0.75rem;
}
._type_dense__B3GbZ._type_right-icon__2pcp4[class*="input-group"] span[class*="input-action"] {
  padding: 0;
  margin: 0;
  margin-right: 0.375rem;
  bottom: 0.4375rem;
}
._type_right-icon__2pcp4[class*="input-group"] span[class*="input-action"] {
  top: auto;
  top: initial;
}

/*-----*/
/*HELPER TEXT*/
._type_def__ZPeEj ._type_error-helper-text__298Tk {
  position: absolute;
  bottom: -1rem;
  z-index: 1;
  /*padding-left: 0.75rem;*/
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  font-weight: var(--font_weight_caption);
  -webkit-animation: _type_shake-horizontal__v88Xb 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
          animation: _type_shake-horizontal__v88Xb 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
._type_dense__B3GbZ ._type_error-helper-text__298Tk {
  position: absolute;
  bottom: -0.875rem;
  z-index: 1;
  /*padding-left: 0.75rem;*/
  font-size: var(--font_size_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
  font-weight: var(--font_weight_caption_small);
  -webkit-animation: _type_shake-horizontal__v88Xb 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
          animation: _type_shake-horizontal__v88Xb 0.2s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

/*ANIMATION*/
@-webkit-keyframes _type_shake-horizontal__v88Xb {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(8px);
  }
  90% {
    transform: translateX(-8px);
  }
}
@keyframes _type_shake-horizontal__v88Xb {
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(8px);
  }
  90% {
    transform: translateX(-8px);
  }
}

/*INPUT*/

/*SMOOTH*/
._view_smooth__tkBw7[class*="input-group"] input[class*="input"],
._view_smooth__tkBw7._view_active__2tTVa._view_isFilled__15ONW[class*="input-group"] input[class*="input"] {
  box-shadow: none;
  background: var(--color5);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__tkBw7[class*="input-group"]._view_active__2tTVa input[class*="input"],
._view_smooth__tkBw7[class*="input-group"] input[class*="input"]:hover {
  color: var(--color100);
  background: var(--color10);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__tkBw7[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_smooth__tkBw7[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_smooth__tkBw7[class*="input-group"] input[class*="input"]::-ms-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_smooth__tkBw7[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_smooth__tkBw7[class*="input-group"] label {
  opacity: 0;
  color: var(--color50);
}
._view_smooth__tkBw7[class*="input-group"]._view_active__2tTVa._view_isFilled__15ONW label,
._view_smooth__tkBw7[class*="input-group"]._view_active__2tTVa label {
  color: var(--color50);
  opacity: 1;
}
._view_smooth__tkBw7[class*="input-group"] input[class*="input"]:focus {
  box-shadow: none;
}
/**END SMOOTH*/

/*OUTLINED*/
._view_outlined__1CFDq[class*="input-group"] input[class*="input"],
._view_outlined__1CFDq._view_active__2tTVa[class*="input-group"] input[class*="input"],
._view_outlined__1CFDq._view_active__2tTVa._view_isFilled__15ONW[class*="input-group"] input[class*="input"] {
  box-shadow: 0 0 0 1px var(--color30);
  background: var(--white);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_outlined__1CFDq[class*="input-group"] input[class*="input"]:hover,
._view_outlined__1CFDq[class*="input-group"]._view_isFilled__15ONW input[class*="input"]:hover {
  color: var(--color100);
  box-shadow: 0 0 0 1px var(--color80);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_outlined__1CFDq[class*="input-group"]._view_active__2tTVa input[class*="input"] {
  box-shadow: 0 0 0 2px var(--color80);
}
._view_outlined__1CFDq[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_outlined__1CFDq[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_outlined__1CFDq[class*="input-group"] input[class*="input"]::-ms-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_outlined__1CFDq[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_outlined__1CFDq[class*="input-group"] label {
  opacity: 0;
  color: var(--color50);
}
._view_outlined__1CFDq[class*="input-group"]._view_active__2tTVa._view_isFilled__15ONW label,
._view_outlined__1CFDq[class*="input-group"]._view_active__2tTVa label {
  color: var(--color50);
  opacity: 1;
}
/**END OUTLINED*/

/*RAISED*/
._view_raised__s8_A8[class*="input-group"] input[class*="input"],
._view_raised__s8_A8._view_active__2tTVa[class*="input-group"] input[class*="input"],
._view_raised__s8_A8._view_active__2tTVa._view_success__3yDA9[class*="input-group"] input[class*="input"],
._view_raised__s8_A8._view_active__2tTVa._view_error-helper-text__2MCIB[class*="input-group"] input[class*="input"],
._view_raised__s8_A8._view_active__2tTVa._view_isFilled__15ONW[class*="input-group"] input[class*="input"] {
  box-shadow: var(--shadow2dp);
  background: var(--white);
  color: var(--color100);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_raised__s8_A8[class*="input-group"] input[class*="input"]:hover,

._view_raised__s8_A8[class*="input-group"]._view_isFilled__15ONW input[class*="input"]:hover {
  color: var(--color100);
  box-shadow: var(--shadow4dp);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_raised__s8_A8[class*="input-group"]._view_active__2tTVa input[class*="input"] {
  box-shadow: var(--shadow8dp);
}
._view_raised__s8_A8[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_raised__s8_A8[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_raised__s8_A8[class*="input-group"] input[class*="input"]::-ms-input-placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_raised__s8_A8[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_raised__s8_A8[class*="input-group"] input[class*="input"]:hover::-webkit-input-placeholder {
  color: var(--color60);
}
._view_raised__s8_A8[class*="input-group"] input[class*="input"]:hover:-ms-input-placeholder {
  color: var(--color60);
}
._view_raised__s8_A8[class*="input-group"] input[class*="input"]:hover::-ms-input-placeholder {
  color: var(--color60);
}
._view_raised__s8_A8[class*="input-group"] input[class*="input"]:hover::placeholder {
  color: var(--color60);
}
._view_raised__s8_A8[class*="input-group"] label {
  color: var(--color50);
  opacity: 0;
}
._view_raised__s8_A8[class*="input-group"]._view_active__2tTVa._view_isFilled__15ONW label,
._view_raised__s8_A8[class*="input-group"]._view_active__2tTVa label {
  color: var(--color50);
  opacity: 1;
}
/**END RAISED*/

/*FILLED*/
._view_filled__1e8U2[class*="input-group"] input[class*="input"],
._view_filled__1e8U2._view_active__2tTVa[class*="input-group"] input[class*="input"],
._view_filled__1e8U2._view_active__2tTVa._view_isFilled__15ONW[class*="input-group"] input[class*="input"] {
  box-shadow: none;
  background: var(--color30);
  color: var(--color0);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__1e8U2[class*="input-group"] input[class*="input"]:hover,
._view_filled__1e8U2[class*="input-group"]._view_isFilled__15ONW input[class*="input"]:hover {
  color: var(--color0);
  box-shadow: none;
  background: var(--color40);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__1e8U2[class*="input-group"]._view_active__2tTVa input[class*="input"] {
  box-shadow: none;
  background: var(--color70);
}
._view_filled__1e8U2[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color0);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_filled__1e8U2[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color0);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_filled__1e8U2[class*="input-group"] input[class*="input"]::-ms-input-placeholder {
  color: var(--color0);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_filled__1e8U2[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color0);
  opacity: 1;
  visibility: visible;
  display: inline;
  display: initial;
}
._view_filled__1e8U2[class*="input-group"] label {
  opacity: 0;
}
._view_filled__1e8U2[class*="input-group"]._view_active__2tTVa._view_isFilled__15ONW label,
._view_filled__1e8U2[class*="input-group"]._view_active__2tTVa label {
  color: var(--color50);
  opacity: 1;
}
/**END FILLED*/

/*HELPER TEXT*/
._view_error-helper-text__2MCIB {
  color: var(--red60);
}
/*end*/

/*RIGHT ICON*/
._view_error-helper-text__2MCIB span[class*="input-action"] {
  color: var(--color60);
}
._view_filled__1e8U2._view_error-helper-text__2MCIB span[class*="input-action"] {
  color: var(--color0);
}
._view_success__3yDA9 span[class*="input-action"] {
  color: var(--color60);
}
._view_filled__1e8U2._view_success__3yDA9 span[class*="input-action"] {
  color: var(--color0);
}
/*-----*/
/*CLEAR BUTTON*/
._view_clearButton__10UjI[class*="input-group"]
  > input
  + span[class*="input-action"]
  > button {
  visibility: hidden;
  opacity: 0;
  cursor: pointer;
}
._view_clearButton__10UjI[class*="input-group"]._view_isFilled__15ONW
  span[class*="input-action"]
  > button,
._view_clearButton__10UjI[class*="input-group"]._view_active__2tTVa span[class*="input-action"] > button {
  visibility: hidden;
  opacity: 0;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_clearButton__10UjI[class*="input-group"]._view_active__2tTVa
  span[class*="input-action"]
  > button[class*="button"],
._view_clearButton__10UjI[class*="input-group"]._view_active__2tTVa
  span[class*="input-action"]
  > button[class*="button"]
  > span[class*="icon"] {
  color: var(--color40) !important;
  opacity: 1;
  visibility: visible;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__1e8U2._view_clearButton__10UjI[class*="input-group"]:hover
  span[class*="input-action"]
  > button[class*="button"]
  > span[class*="icon"] {
  color: var(--color5) !important;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_clearButton__10UjI[class*="input-group"]._view_active__2tTVa._view_isFilled__15ONW
  span[class*="input-action"]
  > button {
  visibility: hidden;
  opacity: 0;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_clearButton__10UjI[class*="input-group"]._view_active__2tTVa._view_isFilled__15ONW:hover
  span[class*="input-action"]
  > button {
  visibility: visible;
  opacity: 1;
  transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
}

/*END CLEAR BUTTON*/

/* COLORS*/
:root {
  --color0: var(--grey0);
  --color5: var(--grey5);
  --color10: var(--grey10);
  --color20: var(--grey20);
  --color30: var(--grey30);
  --color40: var(--grey40);
  --color50: var(--grey50);
  --color60: var(--grey60);
  --color70: var(--grey70);
  --color80: var(--grey80);
  --color90: var(--grey90);
  --color100: var(--grey100);

  --shadow2dp: var(--greyShadow2dp);
  --shadow4dp: var(--greyShadow4dp);
  --shadow8dp: var(--greyShadow8dp);
  --shadow16dp: var(--greyShadow16dp);
  --shadow24dp: var(--greyShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGrey);
  --gradient-reverse: var(--gradGreyRev);
  /*-----------------*/

  /*TABLE*/
  --flat-def-bg-table-hover: var(--color5);
  --flat-def-color-table: var(--color50);
  --flat-def-border-table: var(--color10);
  --flat-def-border2-table: var(--color50);
  --flat-def-borderlast-table: var(--color40);
  --flat-def-color-table-pag: var(--color60);
  --flat-def-color-checkbox: var(--color70);

  --filled-def-bg-table: var(--color40);
  --filled-def-bg-table-hover: var(--color50);
  --filled-def-bg-head-table: var(--color70);
  --filled-def-border-table: var(--color30);
  --filled-def-border2-table: var(--color10);
  --filled-def-head-table-sort: var(--color60);
  --filled-def-color-checkbox: var(--color20);

  --smooth-def-bg-head-table: var(--color10);
  --smooth-def-bg-table: var(--color0);
  --smooth-def-border-table: var(--color10);
  --smooth-def-border2-table: var(--color50);
  --smooth-def-color-table: var(--color50);
  --smooth-def-color-table-pag: var(--color60);

  --raised-def-border-table: var(--color10);
  --raised-def-border2-table: var(--color50);
  --raised-def-prim-color-table: var(--color50);
  /*-------------------*/
}

.color_select__2Ukks {
  /*TABLE*/
  --flat-bg-table-hover: var(--color5);
  --flat-color-table: var(--color50);
  --flat-border-table: var(--color10);
  --flat-border2-table: var(--color50);
  --flat-borderlast-table: var(--color40);
  --flat-color-table-pag: var(--color60);
  --flat-color-checkbox: var(--color70);

  --filled-bg-table: var(--color40);
  --filled-bg-table-hover: var(--color50);
  --filled-bg-head-table: var(--color70);
  --filled-border-table: var(--color30);
  --filled-border2-table: var(--color10);
  --filled-head-table-sort: var(--color60);
  --filled-color-checkbox: var(--color20);

  --smooth-bg-head-table: var(--color10);
  --smooth-bg-table: var(--color0);
  --smooth-border-table: var(--color10);
  --smooth-border2-table: var(--color50);
  --smooth-color-table: var(--color50);
  --smooth-color-table-pag: var(--color60);

  --raised-border-table: var(--color10);
  --raised-border2-table: var(--color50);
  --raised-prim-color-table: var(--color50);
  /*-------------------*/
}
.color_primary__2JdEM {
  --color0: var(--blue0);
  --color5: var(--blue5);
  --color10: var(--blue10);
  --color20: var(--blue20);
  --color30: var(--blue30);
  --color40: var(--blue40);
  --color50: var(--blue50);
  --color60: var(--blue60);
  --color70: var(--blue70);
  --color80: var(--blue80);
  --color90: var(--blue90);
  --color100: var(--blue100);

  --shadow2dp: var(--blueShadow2dp);
  --shadow4dp: var(--blueShadow4dp);
  --shadow8dp: var(--blueShadow8dp);
  --shadow16dp: var(--blueShadow16dp);
  --shadow24dp: var(--blueShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradBlue);
  --gradient-reverse: var(--gradBlueRev);
  /*-----*/
}

.color_danger__OZcfT {
  --color0: var(--red0);
  --color5: var(--red5);
  --color10: var(--red10);
  --color20: var(--red20);
  --color30: var(--red30);
  --color40: var(--red40);
  --color50: var(--red50);
  --color60: var(--red60);
  --color70: var(--red70);
  --color80: var(--red80);
  --color90: var(--red90);
  --color100: var(--red100);

  --shadow2dp: var(--redShadow2dp);
  --shadow4dp: var(--redShadow4dp);
  --shadow8dp: var(--redShadow8dp);
  --shadow16dp: var(--redShadow16dp);
  --shadow24dp: var(--redShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradRed);
  --gradient-reverse: var(--gradRedRev);
  /*-----*/
}
.color_success__38Ixh {
  --color0: var(--green0);
  --color5: var(--green5);
  --color10: var(--green10);
  --color20: var(--green20);
  --color30: var(--green30);
  --color40: var(--green40);
  --color50: var(--green50);
  --color60: var(--green60);
  --color70: var(--green70);
  --color80: var(--green80);
  --color90: var(--green90);
  --color100: var(--green100);

  --shadow2dp: var(--greenShadow2dp);
  --shadow4dp: var(--greenShadow4dp);
  --shadow8dp: var(--greenShadow8dp);
  --shadow16dp: var(--greenShadow16dp);
  --shadow24dp: var(--greenShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGreen);
  --gradient-reverse: var(--gradGreenRev);
  /*-----------------*/
}
.color_warning__3ayA6 {
  --color0: var(--yellow0);
  --color5: var(--yellow5);
  --color10: var(--yellow10);
  --color20: var(--yellow20);
  --color30: var(--yellow30);
  --color40: var(--yellow40);
  --color50: var(--yellow50);
  --color60: var(--yellow60);
  --color70: var(--yellow70);
  --color80: var(--yellow80);
  --color90: var(--yellow90);
  --color100: var(--yellow100);

  --shadow2dp: var(--yellowShadow2dp);
  --shadow4dp: var(--yellowShadow4dp);
  --shadow8dp: var(--yellowShadow8dp);
  --shadow16dp: var(--yellowShadow16dp);
  --shadow24dp: var(--yellowShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradYellow);
  --gradient-reverse: var(--gradYellowRev);
  /*-----------------*/
}
.color_default__1L5X6 {
  --color0: var(--grey0);
  --color5: var(--grey5);
  --color10: var(--grey10);
  --color20: var(--grey20);
  --color30: var(--grey30);
  --color40: var(--grey40);
  --color50: var(--grey50);
  --color60: var(--grey60);
  --color70: var(--grey70);
  --color80: var(--grey80);
  --color90: var(--grey90);
  --color100: var(--grey100);

  --shadow2dp: var(--greyShadow2dp);
  --shadow4dp: var(--greyShadow4dp);
  --shadow8dp: var(--greyShadow8dp);
  --shadow16dp: var(--greyShadow16dp);
  --shadow24dp: var(--greyShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGrey);
  --gradient-reverse: var(--gradGreyRev);
  /*-----------------*/
}

.color_primary_alt__2Xyjt {
  --color0: var(--indigo0);
  --color5: var(--indigo5);
  --color10: var(--indigo10);
  --color20: var(--indigo20);
  --color30: var(--indigo30);
  --color40: var(--indigo40);
  --color50: var(--indigo50);
  --color60: var(--indigo60);
  --color70: var(--indigo70);
  --color80: var(--indigo80);
  --color90: var(--indigo90);
  --color100: var(--indigo100);

  --shadow2dp: var(--indigoShadow2dp);
  --shadow4dp: var(--indigoShadow4dp);
  --shadow8dp: var(--indigoShadow8dp);
  --shadow16dp: var(--indigoShadow16dp);
  --shadow24dp: var(--indigoShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradIndigo);
  --gradient-reverse: var(--gradIndigoRev);
  /*-----*/
}

.color_danger_alt__3xCMP {
  --color0: var(--pink0);
  --color5: var(--pink5);
  --color10: var(--pink10);
  --color20: var(--pink20);
  --color30: var(--pink30);
  --color40: var(--pink40);
  --color50: var(--pink50);
  --color60: var(--pink60);
  --color70: var(--pink70);
  --color80: var(--pink80);
  --color90: var(--pink90);
  --color100: var(--pink100);

  --shadow2dp: var(--pinkShadow2dp);
  --shadow4dp: var(--pinkShadow4dp);
  --shadow8dp: var(--pinkShadow8dp);
  --shadow16dp: var(--pinkShadow16dp);
  --shadow24dp: var(--pinkShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradPink);
  --gradient-reverse: var(--gradPinkRev);
  /*-----*/
}
.color_success_alt__3YDAl {
  --color0: var(--teal0);
  --color5: var(--teal5);
  --color10: var(--teal10);
  --color20: var(--teal20);
  --color30: var(--teal30);
  --color40: var(--teal40);
  --color50: var(--teal50);
  --color60: var(--teal60);
  --color70: var(--teal70);
  --color80: var(--teal80);
  --color90: var(--teal90);
  --color100: var(--teal100);

  --shadow2dp: var(--tealShadow2dp);
  --shadow4dp: var(--tealShadow4dp);
  --shadow8dp: var(--tealShadow8dp);
  --shadow16dp: var(--tealShadow16dp);
  --shadow24dp: var(--tealShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradTeal);
  --gradient-reverse: var(--gradTealRev);
  /*-----------------*/
}
.color_warning_alt__pnF7v {
  --color0: var(--orange0);
  --color5: var(--orange5);
  --color10: var(--orange10);
  --color20: var(--orange20);
  --color30: var(--orange30);
  --color40: var(--orange40);
  --color50: var(--orange50);
  --color60: var(--orange60);
  --color70: var(--orange70);
  --color80: var(--orange80);
  --color90: var(--orange90);
  --color100: var(--orange100);

  --shadow2dp: var(--orangeShadow2dp);
  --shadow4dp: var(--orangeShadow4dp);
  --shadow8dp: var(--orangeShadow8dp);
  --shadow16dp: var(--orangeShadow16dp);
  --shadow24dp: var(--orangeShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradOrange);
  --gradient-reverse: var(--gradOrangeRev);
  /*-----------------*/
}

/* END COLORS*/

/* BUTTONS TYPES*/
/*--DEFAULT--*/
._type_default__2ZIda {
  font-size: var(--font_size_button);
  font-family: var(--font_family_button);
  font-weight: bold;
  text-align: center;
  letter-spacing: var(--letter-spacing-button);
  min-width: var(--s40);
  height: var(--s40);
  min-height: unset;
  padding: 0 1rem 0 1rem;
  line-height: var(--s16);
  border-radius: var(--s4);
  text-transform: capitalize;
}
._type_default__2ZIda._type_dense__1BwCu {
  font-weight: normal;
  letter-spacing: var(--letter_spacing_body_small);
  height: var(--s28);
  padding: 0 0.75rem 0 0.75rem;
}
/*--card--*/
._type_card__1fgPn {
  font-size: var(--font_size_caption);
  font-family: var(--font_family_button);
  line-height: var(--s16);
  text-align: center;
  letter-spacing: var(--letter_spacing_caption);
  text-transform: none;
  min-width: var(--s80);
  height: var(--s56);
  display: inline;
  display: initial;
  word-break: break-all;
  padding: var(--s8) 0;
  border-radius: var(--s4);
  text-transform: capitalize;
}
._type_card__1fgPn p {
  margin: 0;
}
._type_card__1fgPn > span {
  margin-right: 0;
}
._type_card__1fgPn > span[class*="icon"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  margin-bottom: 0.25rem;
}
._type_card__1fgPn[class*="button"] > div[class*="spinner"] {
  position: static;
  position: initial;
  margin-top: 0.625rem;
}
._type_dense__1BwCu._type_card__1fgPn {
  min-width: var(--s56);
  height: var(--s40);
  padding: var(--s6) 0;
  font-size: var(--font_size_caption_small);
  letter-spacing: var(--letter_spacing_caption_small);
}
._type_dense__1BwCu._type_card__1fgPn > span[class*="icon"] {
  margin-bottom: 0;
}
._type_dense__1BwCu._type_card__1fgPn[class*="button"] > div[class*="spinner"] {
  margin-top: 0.375rem;
}
/*--circle--*/
._type_circle__39DY7 {
  height: var(--s56);
  width: var(--s56);
  border-radius: 99px;
}

._type_dense__1BwCu._type_circle__39DY7 {
  height: var(--s40);
  width: var(--s40);
}
/*--ACTION--*/
._type_action__2BPIB {
  font-family: var(--font_family_button);
  font-style: normal;
  font-weight: var(--font_weight_h6);
  font-size: var(--font_size_h6);
  letter-spacing: var(--letter_spacing_h6);
  height: var(--s56);
  border-radius: 99px;
  padding: 0.75rem 1rem;
  text-transform: capitalize;
}
._type_action__2BPIB span {
  margin: 0;
}
._type_action__2BPIB span:first-child[class*="icon"] {
  text-align: left;
  margin-right: 0.75rem;
}
._type_action__2BPIB span:last-child[class*="icon"] {
  margin-left: 0.75rem;
}
._type_dense__1BwCu._type_action__2BPIB {
  font-weight: normal;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body_bold);
  height: var(--s40);
  border-radius: 99px;
  padding: 0.5rem 0.75rem;
}

._type_dense__1BwCu._type_action__2BPIB span:first-child[class*="icon"] {
  margin-right: 0.625rem;
}
._type_dense__1BwCu._type_action__2BPIB span:last-child[class*="icon"] {
  margin-left: 0.625rem;
}
/*--ICON--*/
._type_icon__1SeW9 {
  width: var(--s36);
  height: var(--s36);
  min-width: 0;
  min-height: 0;
  border-radius: 99px;
  padding: 0;
}
._type_dense__1BwCu._type_icon__1SeW9 {
  width: var(--s28);
  height: var(--s28);
}

/*FLAT STYLE*/
._view_flat__1MvlD,
._view_flat__1MvlD[class*="button"],
._view_flat__1MvlD:visited {
  color: var(--color70);
  background: none;
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_flat__1MvlD:hover,
._view_flat__1MvlD[class*="button"]:hover,
._view_flat__1MvlD:hover {
  box-shadow: var(--shadow2dp);
  background: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_flat__1MvlD:active,
._view_flat__1MvlD[class*="button"]:active,
._view_flat__1MvlD[class*="button"][class*="active"],
._view_flat__1MvlD[class*="button"]._view_focused__2-SCh {
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_flat__1MvlD[class*="disabled"]:disabled,
._view_flat__1MvlD[class*="disabled"]:disabled > span > svg,
._view_flat__1MvlD[class*="disabled"]:disabled:hover,
._view_flat__1MvlD[class*="disabled"]:disabled:active {
  color: var(--color30);
  opacity: 1;
  background-color: var(--color0);
  box-shadow: none;
}
/*END STYLE*/

/* FILLED STYLE*/
._view_filled__3oNxN[class*="button"],
._view_filled__3oNxN[class*="button"]:visited {
  box-shadow: var(--shadow4dp);
  background: var(--color60);
  color: var(--color0);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_filled__3oNxN[class*="button"]:hover {
  background: var(--color70);
  box-shadow: var(--shadow8dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_filled__3oNxN[class*="button"]:active,
._view_filled__3oNxN[class*="button"][class*="active"]:active,
._view_filled__3oNxN[class*="button"]._view_focused__2-SCh {
  background: var(--color80);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_filled__3oNxN[class*="disabled"]:disabled {
  color: var(--color40);
  background-color: var(--color5);
  box-shadow: none;
  opacity: 1;
}
/* END STYLE */

/*SMOOTH STYLE*/
._view_smooth__rbzq8[class*="button"],
._view_smooth__rbzq8[class*="button"]:visited {
  color: var(--color70);
  background: var(--color5);
  box-shadow: none;
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_smooth__rbzq8[class*="button"]:hover {
  color: var(--color100);
  background: var(--color10);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_smooth__rbzq8[class*="button"]:active,
._view_smooth__rbzq8[class*="button"]:active,
._view_smooth__rbzq8[class*="button"][class*="active"],
._view_smooth__rbzq8[class*="button"]._view_focused__2-SCh {
  background: var(--color20);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_smooth__rbzq8[class*="disabled"]:disabled,
._view_smooth__rbzq8[class*="disabled"]:disabled:hover {
  color: var(--color30);
  background-color: var(--color0);
  opacity: 1;
}
/* END STYLE */

/* OUTLINED STYLE*/
._view_outlined__2ENQg[class*="button"],
._view_outlined__2ENQg[class*="button"]:visited {
  background: none;
  box-shadow: 0 0 0 1px var(--color30); /*border: 1px solid var(--color30);*/
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_outlined__2ENQg[class*="button"]:hover {
  box-shadow: 0 0 0 2px var(--color30);
  width: calc(- 2px);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_outlined__2ENQg[class*="button"]:active,
._view_outlined__2ENQg[class*="button"]._view_focused__2-SCh {
  color: var(--color100);
  background-color: var(--color5);
  box-shadow: 0 0 0 2px var(--color50);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_outlined__2ENQg[class*="disabled"]:disabled {
  background-color: unset;
  color: var(--color30);
  box-shadow: 0 0 0 1px var(--color10);
}
/* END STYLE */

/* RAISED STYLE */
._view_raised__1L_nJ[class*="button"],
._view_raised__1L_nJ[class*="button"]:visited {
  background: var(--color0);
  color: var(--color70);
  box-shadow: var(--shadow4dp);
  transition: all 0.3s cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
._view_raised__1L_nJ[class*="button"]:hover {
  background: var(--white);
  color: var(--color100);
  box-shadow: var(--shadow8dp);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_raised__1L_nJ[class*="button"]:active,
._view_raised__1L_nJ[class*="button"]._view_focused__2-SCh {
  box-shadow: var(--shadow2dp);
  background-color: var(--white);
  color: var(--color70);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
._view_raised__1L_nJ[class*="disabled"]:disabled {
  color: var(--color40);
  background-color: var(--color5);
  box-shadow: none;
}
/* END STYLE */

/**TOAST*/
._type_def__X5Qsx > div {
  height: auto;
  border-radius: 0.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  z-index: 999;
}
._type_def__X5Qsx span[class*="toast-message"] {
  padding: 0;
  margin: auto;
  margin-left: 1rem;
  font-size: var(--font_size_h6);
  font-family: var(--font_secondary);
  letter-spacing: var(--letter_spacing_h6);
  font-weight: bold;
}
._type_def__X5Qsx div[class*="button-group"] {
  padding: 0;
  margin: auto;
  margin-right: 1rem;
}
._type_def__X5Qsx div[class*="button-group"] svg {
  width: 1.5rem;
  height: 1.5rem;
}
._type_def__X5Qsx div[class*="button-group"] a {
  margin-left: 0.5rem;
  font-family: var(--font_primary);
  text-transform: uppercase;
  font-size: var(--font_size_button);
  letter-spacing: var(--letter_spacing_button);
  font-weight: var(--font_weight_button);
}

._type_def__X5Qsx span[class*="icon"]:first-child {
  float: left;
  margin: auto;
  border-radius: 99px;
  margin-left: 1rem;
  width: 2.5rem;
  height: 2.5rem;
}
._type_def__X5Qsx span[class*="icon"]:first-child > svg {
  margin: auto;
  margin-top: 0.5rem;
}

._type_def__X5Qsx._type_no-close__3Pujv div[class*="button-group"] button[class*="button"] {
  display: none;
}

/*TOAST*/

/*flat*/

/*filled*/

._view_filled__SnYD4 > div {
  background-color: var(--color70);
  color: var(--color10);
  box-shadow: none;
}
._view_filled__SnYD4 div[class*="button-group"] svg {
  color: var(--color40);
  transition: all 180ms ease-in;
}
._view_filled__SnYD4 div[class*="button-group"] > button:hover svg,
._view_filled__SnYD4 div[class*="button-group"] > button[class*="button"]:hover,
._view_filled__SnYD4 div[class*="button-group"] a[class*="button"]:hover {
  color: var(--color10);
  background: none;
  transition: all 180ms ease-in;
}
._view_filled__SnYD4 div[class*="button-group"] a {
  color: var(--color30);
  transition: all 180ms ease-in;
}

._view_filled__SnYD4 svg,
._view_filled__SnYD4 span[class*="icon"]:first-child {
  color: var(--color10);
}

._view_filled__SnYD4 div > span[class*="icon"]:first-child {
  background: var(--color80);
}

/*smooth*/

._view_smooth__2llJR > div {
  background-color: var(--color5);
  color: var(--color80);
  box-shadow: none;
}
._view_smooth__2llJR div[class*="button-group"] svg {
  color: var(--color40);
  transition: all 180ms ease-in;
}
._view_smooth__2llJR div[class*="button-group"] > button:hover svg,
._view_smooth__2llJR div[class*="button-group"] > button[class*="button"]:hover {
  color: var(--color80);
  background: none;
  transition: all 180ms ease-in;
}
._view_smooth__2llJR div[class*="button-group"] a[class*="button"] {
  color: var(--color70);
  background: var(--white);
  padding: 0.75rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-left: 0.5rem;
  border-radius: 4px;
  box-shadow: var(--shadow4dp);
  transition: all 180ms ease-in;
}
._view_smooth__2llJR div[class*="button-group"] a[class*="button"]:hover {
  color: var(--color70);
  background: var(--white);
  box-shadow: var(--shadow8dp);
}
._view_smooth__2llJR div[class*="button-group"] a[class*="button"]:active {
  box-shadow: var(--shadow2dp);
}

._view_smooth__2llJR svg,
._view_smooth__2llJR span[class*="icon"]:first-child {
  color: var(--color80);
}

._view_smooth__2llJR div > span[class*="icon"]:first-child {
  background: var(--color20);
}

/*OUTLINED*/

._view_outlined__-KUTr > div {
  background-color: var(--white);
  color: var(--color80);
  border: 1px solid var(--color30);
  box-shadow: none;
}
._view_outlined__-KUTr div[class*="button-group"] svg {
  color: var(--color40);
  transition: all 180ms ease-in;
}
._view_outlined__-KUTr div[class*="button-group"] > button:hover svg,
._view_outlined__-KUTr div[class*="button-group"] > button[class*="button"]:hover {
  color: var(--color80);
  background: none;
  transition: all 180ms ease-in;
}
/*close button*/
._view_outlined__-KUTr div[class*="button-group"] a[class*="button"] {
  color: var(--color70);
  background: var(--color5);
  padding: 0.75rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-left: 0.5rem;
  border-radius: 4px;
  /*box-shadow: var(--shadow4dp);*/
  transition: all 180ms ease-in;
}
._view_outlined__-KUTr div[class*="button-group"] a[class*="button"]:hover {
  color: var(--color70);
  background: var(--color5);
  box-shadow: var(--shadow4dp);
}
._view_outlined__-KUTr div[class*="button-group"] a[class*="button"]:active {
  box-shadow: none;
  background: var(--color10);
}
/**/

/*icon*/
._view_outlined__-KUTr svg,
._view_outlined__-KUTr span[class*="icon"]:first-child {
  color: var(--color80);
}

._view_outlined__-KUTr div > span[class*="icon"]:first-child {
  background: var(--color10);
}

/*RAISED*/

._view_raised__1QHeM > div {
  background-color: var(--white);
  color: var(--color80);
  box-shadow: var(--shadow4dp);
}
._view_raised__1QHeM div[class*="button-group"] svg {
  color: var(--grey50);
  transition: all 180ms ease-in;
}
._view_raised__1QHeM div[class*="button-group"] > button:hover svg,
._view_raised__1QHeM div[class*="button-group"] > button[class*="button"]:hover {
  color: var(--grey90);
  background: none;
  transition: all 180ms ease-in;
}
/*close button*/
._view_raised__1QHeM div[class*="button-group"] a[class*="button"] {
  color: var(--color70);
  background: var(--color5);
  padding: 0.75rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  margin-left: 0.5rem;
  border-radius: 4px;
  /*box-shadow: var(--shadow4dp);*/
  transition: all 180ms ease-in;
}
._view_raised__1QHeM div[class*="button-group"] a[class*="button"]:hover {
  color: var(--color70);
  background: var(--color5);
  box-shadow: var(--shadow4dp);
}
._view_raised__1QHeM div[class*="button-group"] a[class*="button"]:active {
  box-shadow: none;
  background: var(--color10);
}
/*icon*/
._view_raised__1QHeM svg,
._view_raised__1QHeM span[class*="icon"]:first-child {
  color: var(--color80);
}
._view_raised__1QHeM div > span[class*="icon"]:first-child {
  background: var(--color10);
}

/**NAVBAR*/
div[class*="align-left"] {
  margin-right: auto;
}
div[class*="navbar-heading"] {
  margin-right: auto;
}
._type_desktop__3I4ll {
  display: flex;
  height: 4.5rem;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 0 1rem;
}
._type_desktop__3I4ll div[class*="navbar-group"] {
  height: 4.5rem;
}

._type_tablet__2oPNj {
  display: flex;
  height: 3.5rem;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 0 1rem;
}
._type_tablet__2oPNj div[class*="navbar-group"] {
  height: 3.5rem;
}

._type_mobile__3C6Sm {
  height: 6rem;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 0.625rem 0.5rem;
}

/*GROUP ALIGN CENTER*/
._type_center__2zAS7 {
  align-content: space-between;
  /* display: flex; */
  margin-left: auto;
  margin-right: auto;
}
._type_center__2zAS7 a:not(:first-of-type) {
  text-decoration: none;
  padding-left: 0.75rem;
}
._type_mobile__3C6Sm div[class*="navbar-group"] {
  height: auto;
  height: initial;
}
._type_mobile__3C6Sm div[class*="center"] {
  position: absolute;
  top: 100%;
  left: 0;
  margin-right: 0;
  transform: translate(0%, -100%);
  /*overflow-x: auto;*/
  width: 100%;
  overflow-y: hidden;
  padding: 0 0.5rem 0.625rem 0.5rem;
}

/**/

/*NAVBAR*/
._view_flat__1AqiX {
  box-shadow: none;
  background: var(--white);
}
._view_smooth__ELTWI {
  box-shadow: none;
  background: var(--color5);
}
._view_raised__1TrKd {
  box-shadow: var(--shadow2dp);
  background: var(--white);
}
._view_filled__HghW- {
  box-shadow: var(--shadow2dp);
  background: var(--color70);
}

/**INPUT*/
._type_def__3L-xJ._type_fill__1RnD6,
._type_dense__1YR-d._type_fill__1RnD6 {
  width: 100%;
}
._type_def__3L-xJ,
._type_dense__1YR-d {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
._type_def__3L-xJ[class*="input-group"] > input[class*="input"] {
  height: 2.5rem;
  border-radius: 0.25rem;
  padding: 0 2.5rem !important;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  font-weight: var(--font_weight_body);
}
._type_dense__1YR-d[class*="input-group"] > input[class*="input"] {
  height: 1.75rem;
  border-radius: 0.25rem;
  padding: 0 1.75rem !important;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_small);
}

._type_def__3L-xJ[class*="input-group"] > button[class*="button"],
._type_dense__1YR-d[class*="input-group"] > button[class*="button"],
._type_def__3L-xJ[class*="input-group"]
  span[class*="input-action"]
  > button[class*="button"],
._type_dense__1YR-d[class*="input-group"]
  span[class*="input-action"]
  > button[class*="button"] {
  z-index: 1;
  margin: 0;
  padding: 0;
  width: 1.25rem;
  height: 100%;
  min-width: 1.25rem;
  min-height: 0;
  margin-left: 0.75rem;
}

._type_def__3L-xJ[class*="input-group"] span[class*="input-action"],
._type_dense__1YR-d[class*="input-group"] span[class*="input-action"] {
  height: 100%;
}
._type_def__3L-xJ[class*="input-group"]
  span[class*="input-action"]
  > button[class*="button"] {
  margin-right: 0.625rem;
}
._type_dense__1YR-d[class*="input-group"] > button[class*="button"] {
  width: 1rem;
  min-width: 1rem;
  margin-left: 0.5rem;
}
._type_dense__1YR-d[class*="input-group"]
  span[class*="input-action"]
  > button[class*="button"] {
  margin-right: 0.375rem;
}

._type_def__3L-xJ[class*="active"] > input[class*="input"],
._type_dense__1YR-d[class*="active"] > input[class*="input"] {
  font-weight: var(--font_weight_body_medium);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

/*INPUT*/
/*SMOOTH*/
._view_smooth__2CS_C[class*="input-group"] input[class*="input"] {
  box-shadow: none;
  background: var(--color5);
  color: var(--color100);
  border: none;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__2CS_C[class*="input-group"] input[class*="input"]:hover {
  box-shadow: none;
  background: var(--color10);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__2CS_C[class*="input-group"]._view_active__9wqyf input[class*="input"] {
  box-shadow: none;
  background: var(--color10);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__2CS_C[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color40);
}
._view_smooth__2CS_C[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color40);
}
._view_smooth__2CS_C[class*="input-group"] input[class*="input"]::-ms-input-placeholder {
  color: var(--color40);
}
._view_smooth__2CS_C[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
}
._view_smooth__2CS_C[class*="input-group"]:hover input[class*="input"]::-webkit-input-placeholder {
  color: var(--color60);
}
._view_smooth__2CS_C[class*="input-group"]:hover input[class*="input"]:-ms-input-placeholder {
  color: var(--color60);
}
._view_smooth__2CS_C[class*="input-group"]:hover input[class*="input"]::-ms-input-placeholder {
  color: var(--color60);
}
._view_smooth__2CS_C[class*="input-group"]:hover input[class*="input"]::placeholder {
  color: var(--color60);
}
/**END SMOOTH*/

/*FILLED*/
._view_filled__qvDJD[class*="input-group"] input[class*="input"] {
  box-shadow: none;
  background: var(--color30);
  color: var(--color0);
  border: none;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__qvDJD[class*="input-group"] input[class*="input"]:hover {
  box-shadow: none;
  background: var(--color40);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__qvDJD[class*="input-group"]._view_active__9wqyf input[class*="input"] {
  box-shadow: none;
  background: var(--color70);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__qvDJD[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color0);
}
._view_filled__qvDJD[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color0);
}
._view_filled__qvDJD[class*="input-group"] input[class*="input"]::-ms-input-placeholder {
  color: var(--color0);
}
._view_filled__qvDJD[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color0);
}

/**END FILLED*/

/*OUTLINED*/
._view_outlined__yzDrk[class*="input-group"] input[class*="input"] {
  box-shadow: 0 0 0 0.0625rem var(--color30);
  color: var(--color100);
  border: none;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_outlined__yzDrk[class*="input-group"] input[class*="input"]:hover {
  box-shadow: 0 0 0 0.0625rem var(--color80);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_outlined__yzDrk[class*="input-group"]._view_active__9wqyf input[class*="input"] {
  box-shadow: 0 0 0 0.125rem var(--color80);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_outlined__yzDrk[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color40);
}
._view_outlined__yzDrk[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color40);
}
._view_outlined__yzDrk[class*="input-group"] input[class*="input"]::-ms-input-placeholder {
  color: var(--color40);
}
._view_outlined__yzDrk[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
}
/**END OUTLINED*/

/*RAISED*/
._view_raised__151Oj[class*="input-group"] input[class*="input"] {
  box-shadow: var(--shadow2dp);
  color: var(--color100);
  border: none;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_raised__151Oj[class*="input-group"] input[class*="input"]:hover {
  box-shadow: var(--shadow4dp);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_raised__151Oj[class*="input-group"]._view_active__9wqyf input[class*="input"] {
  box-shadow: var(--shadow8dp);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_raised__151Oj[class*="input-group"] input[class*="input"]::-webkit-input-placeholder {
  color: var(--color40);
}
._view_raised__151Oj[class*="input-group"] input[class*="input"]:-ms-input-placeholder {
  color: var(--color40);
}
._view_raised__151Oj[class*="input-group"] input[class*="input"]::-ms-input-placeholder {
  color: var(--color40);
}
._view_raised__151Oj[class*="input-group"] input[class*="input"]::placeholder {
  color: var(--color40);
}
._view_raised__151Oj[class*="input-group"]:hover input[class*="input"]::-webkit-input-placeholder {
  color: var(--color60);
}
._view_raised__151Oj[class*="input-group"]:hover input[class*="input"]:-ms-input-placeholder {
  color: var(--color60);
}
._view_raised__151Oj[class*="input-group"]:hover input[class*="input"]::-ms-input-placeholder {
  color: var(--color60);
}
._view_raised__151Oj[class*="input-group"]:hover input[class*="input"]::placeholder {
  color: var(--color60);
}
/**END RAISED*/

/*SEARCH BUTTON*/
div[class*="input-group"] > ._view_searchButton__2No8B {
  color: var(--color30);
}
div[class*="input-group"] > ._view_searchButton__2No8B:hover {
  color: var(--color50);
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_smooth__2CS_C[class*="input-group"]._view_active__9wqyf > ._view_searchButton__2No8B {
  color: var(--color60);
}
._view_filled__qvDJD[class*="input-group"] > ._view_searchButton__2No8B,
._view_filled__qvDJD[class*="input-group"]._view_active__9wqyf > ._view_searchButton__2No8B:hover {
  color: var(--color20);
}
._view_filled__qvDJD[class*="input-group"]._view_active__9wqyf > ._view_searchButton__2No8B,
._view_filled__qvDJD[class*="input-group"] > ._view_searchButton__2No8B:hover {
  color: var(--color0);
}

/*-----*/
/*CLEAR BUTTON*/

div[class*="input-group"]
  > input[class*="input"]
  + span[class*="input-action"]
  > button[class*="minimal"]._view_clearButton__1f2Je:not(:hover):not(:focus)
  > span[class*="icon"]
  > svg {
  fill: var(--color30) !important;
}
div[class*="input-group"] span[class*="input-action"] > button {
  color: var(--color30) !important;
}
._view_smooth__2CS_C[class*="input-group"]
  > input[class*="input"]
  + span[class*="input-action"]
  > button[class*="minimal"]._view_clearButton__1f2Je:not(:hover):not(:focus)
  > span[class*="icon"]
  > svg,
._view_raised__151Oj[class*="input-group"]
  > input[class*="input"]
  + span[class*="input-action"]
  > button[class*="minimal"]._view_clearButton__1f2Je:not(:hover):not(:focus)
  > span[class*="icon"]
  > svg {
  fill: var(--color40) !important;
}
._view_smooth__2CS_C[class*="input-group"] span[class*="input-action"] > button,
._view_raised__151Oj[class*="input-group"] span[class*="input-action"] > button {
  color: var(--color40) !important;
}
._view_filled__qvDJD[class*="input-group"] span[class*="input-action"] > button {
  color: var(--color50) !important;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__qvDJD[class*="input-group"]._view_active__9wqyf span[class*="input-action"] > button {
  color: var(--color40) !important;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_filled__qvDJD[class*="input-group"]
  > input[class*="input"]
  + span[class*="input-action"]
  > button[class*="minimal"]._view_clearButton__1f2Je:not(:hover):not(:focus)
  > span[class*="icon"]
  > svg {
  fill: var(--color40) !important;
}

div[class*="input-group"]._view_isFilled__EcthQ
  span[class*="input-action"]
  > button._view_clearButton__1f2Je {
  visibility: hidden;
  opacity: 0;
}
._view_div__117Mi[class*="input-group"]._view_isFilled__EcthQ span[class*="input-action"] > button {
  visibility: hidden;
  opacity: 0;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
._view_div__117Mi[class*="input-group"]
  > input[class*="input"]
  + span[class*="input-action"]
  > button[class*="minimal"]._view_clearButton__1f2Je:not(:hover):not(:focus)
  > span[class*="icon"] {
  opacity: 1;
  visibility: visible;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

div[class*="input-group"] span[class*="input-action"] > button {
  visibility: hidden;
}
div[class*="input-group"] span[class*="input-action"] > ._view_clearButton__1f2Je {
  visibility: visible;
}
div[class*="input-group"]._view_isFilled__EcthQ span[class*="input-action"] > ._view_clearButton__1f2Je {
  visibility: hidden;
  opacity: 0;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
div[class*="input-group"]._view_isFilled__EcthQ:hover
  span[class*="input-action"]
  > ._view_clearButton__1f2Je {
  visibility: visible;
  opacity: 1;
  transition: all 150ms cubic-bezier(0.4, 0, 0.2, 1);
}
/*-----*/

._type_default__n2VBi {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  border-radius: 0.5rem;
}
._type_default__n2VBi div[class*="DayPicker-Day"],
._type_default__n2VBi div[class*="DayPicker-Day"] > div[class*="day-wrapper"] {
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
}

._type_default__n2VBi div[class*="DayPicker-Weekday"] {
  padding: unset;
  width: 2.5rem;
  height: 1.5rem;
  font-size: var(--font_size_body_small);
  font-weight: var(--font_weight_body_medium);
  letter-spacing: var(--letter_spacing_body_small);
}

/*NavBar*/
._type_default__n2VBi div[class*="datepicker-caption"] {
  height: 2.5rem;
  margin: 0 2rem;
}
._type_default__n2VBi div[class*="datepicker-navbar"][class*="DayPicker-NavBar"] {
  left: 0 !important;
}
._type_default__n2VBi div[class*="datepicker-navbar"],
._type_default__n2VBi div[class*="datepicker-navbar"] > button {
  height: 2.5rem;
}
._type_default__n2VBi div[class*="datepicker-navbar"] > button svg {
  width: 1.25rem;
  height: 1.25rem;
}
._type_default__n2VBi div[class*="datepicker-month-select"] > select,
._type_default__n2VBi div[class*="datepicker-year-select"] > select {
  font-family: inherit;
  font-size: var(--font_size_body_small);
  font-weight: var(--font_weight_body_medium);
  letter-spacing: var(--letter_spacing_body_small);
  height: 2.5rem;
  padding: 0;
  margin: 0;
  padding-left: 0.5rem;
}
._type_default__n2VBi div[class*="datepicker-year-select"] > select {
  min-width: 68px;
}

._type_default__n2VBi div[class*="datepicker-month-select"] span > svg,
._type_default__n2VBi div[class*="datepicker-year-select"] span > svg {
  height: 2.5rem;
  width: 1.25rem;
}
._type_default__n2VBi div[class*="datepicker-month-select"] span,
._type_default__n2VBi div[class*="datepicker-year-select"] span {
  top: unset;
}

/*end*/
/*footer*/
._type_default__n2VBi div[class*="datepicker-footer"] > button {
  line-height: unset;
  height: 2.5rem;
  padding: 0 1rem;
}
/**/

/*shortcuts*/
._type_default__n2VBi ul[class*="daterangepicker-shortcuts"] {
  padding-left: 1rem;
  padding-right: 1rem;
}
._type_default__n2VBi ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"] {
  height: 2.5rem;
  padding: 0;
  margin: 0.25rem;
  border-radius: 0.25rem;
}
._type_default__n2VBi ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"] > div {
  margin: auto 1rem;
}
/**/

/*FLAT STYLE*/
._view_flat__QBH9l {
  color: var(--color100);
}
._view_flat__QBH9l div[class*="DayPicker-Day"] > div[class*="day-wrapper"] {
  border: none !important;
  padding: unset;
}
._view_flat__QBH9l div[class*="DayPicker-Day"][class*="DayPicker-Day--selected"],
._view_flat__QBH9l div[class*="DayPicker-Day"][class*="DayPicker-Day--selected"]:hover {
  background: var(--color5);
  color: var(--color100);
  border-radius: 0;
}

/*--DIVIDER*/
._view_flat__QBH9l div[class*="divider"] {
  border-bottom: 1px solid var(--color5);
  border-right: 1px solid var(--color5);
  margin: 0.5rem;
}
/**/
/*--DAY SELECTED START/END */
._view_flat__QBH9l div[class*="--selected-range-start"][aria-selected="true"],
._view_flat__QBH9l
  div[class*="--selected-range-end"][class*="--hovered-range-start"][aria-selected="true"] {
  border-radius: 99px 8px 8px 99px !important;
  background-color: var(--color0);
  box-shadow: inset 0 0 0 2px var(--color60);
}
._view_flat__QBH9l div[class*="--selected-range-end"][aria-selected="true"],
._view_flat__QBH9l
  div[class*="--selected-range-start"][class*="--hovered-range-end"][aria-selected="true"] {
  border-radius: 8px 99px 99px 8px !important;
  background-color: var(--color0);
  box-shadow: inset 0 0 0 2px var(--color60);
}
._view_flat__QBH9l div[class*="--selected-range-start"][aria-selected="true"]:hover,
._view_flat__QBH9l div[class*="--selected-range-end"][aria-selected="true"]:hover {
  background-color: var(--color5);
}

._view_flat__QBH9l div [class*="--selected-range-start"][class*="--selected-range-end"],
._view_flat__QBH9l
  div
  [class*="--selected-range-start"][class*="--selected-range-end"]:hover {
  border-radius: 50% !important;
  background-color: var(--color0);
  color: var(--color100);
}
._view_flat__QBH9l div[class*="--hovered-range-start"]:not([class*="--selected"]) {
  border-radius: 99px 8px 8px 99px !important;
  box-shadow: inset 0 0 0 1px var(--color30);
  background-color: var(--color5);
}
._view_flat__QBH9l div[class*="--hovered-range-end"]:not([class*="--selected"]) {
  border-radius: 8px 99px 99px 8px !important;
  box-shadow: inset 0 0 0 1px var(--color30);
  background-color: var(--color5);
}

._view_flat__QBH9l div[class*="--selected-range"][aria-selected="false"] {
  background-color: var(--color5);
}
._view_flat__QBH9l div[class*="--selected-range"][aria-selected="false"]:hover {
  background-color: var(--color10);
}
._view_flat__QBH9l
  div[class*="--hovered-range"][aria-selected="false"]:not([class*="range-start"]):not([class*="range-end"]) {
  background-color: var(--color5) !important;
}
._view_flat__QBH9l div[class*="DayPicker"][aria-selected="false"]:focus {
  background: transparent;
  color: var(--color100);
}
/**/

/*shortcut*/
._view_flat__QBH9l ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"] {
  color: var(--color70);
}
._view_flat__QBH9l
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"],
._view_flat__QBH9l ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"]:hover {
  background: var(--color5);
}
._view_flat__QBH9l
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"] {
  font-weight: 500;
}
/*end*/

/*navbar*/
._view_flat__QBH9l div[class*="datepicker-navbar"] > button {
  width: 2.5rem;
  border-radius: 50%;
  color: var(--color70);
}
._view_flat__QBH9l div[class*="datepicker-navbar"] > button:hover {
  color: var(--color100);
  box-shadow: var(--shadow2dp);
}
/*end*/

/*month selector*/
._view_flat__QBH9l div[class*="datepicker-month-select"] > select,
._view_flat__QBH9l div[class*="datepicker-year-select"] > select {
  color: var(--color70);
}
._view_flat__QBH9l div[class*="datepicker-month-select"] > select:hover,
._view_flat__QBH9l div[class*="datepicker-year-select"] > select:hover {
  background: var(--color5);
  color: var(--color100);
}
._view_flat__QBH9l div[class*="datepicker-month-select"] > span[class*="icon"],
._view_flat__QBH9l div[class*="datepicker-year-select"] > span[class*="icon"] {
  color: var(--color70);
}
/*end*/

/*footer*/
._view_flat__QBH9l div[class*="datepicker-footer"] > button:hover {
  background: var(--color5);
}
/*end*/

._view_flat__QBH9l div[class*="DayPicker-Day--outside"] > div[class*="day-wrapper"] {
  color: var(--color40);
}
._view_flat__QBH9l div[class*="DayPicker-Day"]:hover {
  background: var(--color0);
}
/*END STYLE*/

/*SMOOTH STYLE*/
._view_smooth__1LYL- {
  color: var(--color100);
  background: var(--color0);
}
._view_smooth__1LYL- div[class="DayPicker-Day"] > div[class*="day-wrapper"] {
  border: none !important;
  padding: unset;
}
/*--DAY SELECTED START/END */
._view_smooth__1LYL- div[class*="--selected-range-start"][aria-selected="true"],
._view_smooth__1LYL-
  div[class*="--selected-range-end"][class*="--hovered-range-start"][aria-selected="true"] {
  border-radius: 99px 8px 8px 99px !important;
  background-color: var(--color60);
}
._view_smooth__1LYL- div[class*="--selected-range-end"][aria-selected="true"],
._view_smooth__1LYL-
  div[class*="--selected-range-start"][class*="--hovered-range-end"][aria-selected="true"] {
  border-radius: 8px 99px 99px 8px !important;
  background-color: var(--color60);
}
._view_smooth__1LYL- div[class*="--selected-range-start"][aria-selected="true"]:hover,
._view_smooth__1LYL- div[class*="--selected-range-end"][aria-selected="true"]:hover {
  background-color: var(--color70);
}

._view_smooth__1LYL- div [class*="--selected-range-start"][class*="--selected-range-end"],
._view_smooth__1LYL-
  div
  [class*="--selected-range-start"][class*="--selected-range-end"]:hover {
  border-radius: 50% !important;
  background-color: var(--color60);
  color: var(--color0);
}
._view_smooth__1LYL- div[class*="--hovered-range-start"]:not([class*="--selected"]) {
  border-radius: 99px 8px 8px 99px !important;
  box-shadow: inset 0 0 0 1px var(--color30);
  background-color: var(--color5);
}
._view_smooth__1LYL- div[class*="--hovered-range-end"]:not([class*="--selected"]) {
  border-radius: 8px 99px 99px 8px !important;
  box-shadow: inset 0 0 0 1px var(--color30);
  background-color: var(--color5);
}

._view_smooth__1LYL- div[class*="--selected-range"][aria-selected="false"] {
  background-color: var(--color10);
}
._view_smooth__1LYL- div[class*="--selected-range"][aria-selected="false"]:hover {
  background-color: var(--color20);
}
._view_smooth__1LYL-
  div[class*="--hovered-range"][aria-selected="false"]:not([class*="range-start"]):not([class*="range-end"]) {
  background-color: var(--color10) !important;
}
._view_smooth__1LYL- div[class*="DayPicker"][aria-selected="false"]:focus {
  background: transparent;
  color: var(--color100);
}
/**/

/*shortcut*/
._view_smooth__1LYL- ul[class*="daterangepicker-shortcuts"] {
  background: var(--color0);
}
._view_smooth__1LYL- ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"] {
  color: var(--color70);
}
._view_smooth__1LYL-
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"],
._view_smooth__1LYL- ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"]:hover {
  background: var(--color0);
  box-shadow: 0 0 0 1px var(--color30);
}
._view_smooth__1LYL-
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"] {
  font-weight: 500;
}
/*end*/
/*--DIVIDER*/
._view_smooth__1LYL- div[class*="divider"] {
  border-bottom: 1px solid var(--color10);
  border-right: 1px solid var(--color10);
  margin: 0.5rem;
}
/**/
/*navbar*/
._view_smooth__1LYL- div[class*="datepicker-navbar"] > button {
  width: 2.5rem;
  border-radius: 50%;
  color: var(--color70);
}
._view_smooth__1LYL- div[class*="datepicker-navbar"] > button:hover {
  color: var(--color100);
  box-shadow: var(--shadow2dp);
}

/*end*/

/*month selector*/
._view_smooth__1LYL- div[class*="datepicker-month-select"] > select,
._view_smooth__1LYL- div[class*="datepicker-year-select"] > select {
  color: var(--color70);
}
._view_smooth__1LYL- div[class*="datepicker-month-select"] > select:hover,
._view_smooth__1LYL- div[class*="datepicker-year-select"] > select:hover {
  background: var(--color5);
  color: var(--color100);
}
._view_smooth__1LYL- div[class*="datepicker-month-select"] > span[class*="icon"],
._view_smooth__1LYL- div[class*="datepicker-year-select"] > span[class*="icon"] {
  color: var(--color70);
}
/*end*/

/*footer*/
._view_smooth__1LYL- div[class*="datepicker-footer"] > button:hover {
  background: var(--color5);
}
/*end*/

._view_smooth__1LYL- div[class*="DayPicker-Day--outside"] > div[class*="day-wrapper"] {
  color: var(--color40);
}
._view_smooth__1LYL- div[class*="DayPicker-Day"]:hover {
  background: var(--color0);
}
/* END STYLE */

/* RAISED STYLE */
._view_raised__3SCFL {
  color: var(--color100);
  background: var(--white);
  box-shadow: var(--shadow16dp);
}
._view_raised__3SCFL div[class*="DayPicker-Day"] > div[class*="day-wrapper"] {
  border: none !important;
  padding: unset;
}
._view_raised__3SCFL div[class*="DayPicker-Day"][class*="DayPicker-Day--selected"],
._view_raised__3SCFL div[class*="DayPicker-Day"][class*="DayPicker-Day--selected"]:hover {
  background: var(--color5);
  color: var(--color100);
  border-radius: 0;
}

/*--DIVIDER*/
._view_raised__3SCFL div[class*="divider"] {
  border-bottom: 1px solid var(--color5);
  border-right: 1px solid var(--color5);
  margin: 0.5rem;
}
/**/
/*--DAY SELECTED START/END */
._view_raised__3SCFL div[class*="--selected-range-start"][aria-selected="true"],
._view_raised__3SCFL
  div[class*="--selected-range-end"][class*="--hovered-range-start"][aria-selected="true"] {
  border-radius: 99px 8px 8px 99px !important;
  background-color: var(--color0);
  box-shadow: inset 0 0 0 2px var(--color60);
}
._view_raised__3SCFL div[class*="--selected-range-end"][aria-selected="true"],
._view_raised__3SCFL
  div[class*="--selected-range-start"][class*="--hovered-range-end"][aria-selected="true"] {
  border-radius: 8px 99px 99px 8px !important;
  background-color: var(--color0);
  box-shadow: inset 0 0 0 2px var(--color60);
}
._view_raised__3SCFL div[class*="--selected-range-start"][aria-selected="true"]:hover,
._view_raised__3SCFL div[class*="--selected-range-end"][aria-selected="true"]:hover {
  background-color: var(--color5);
}

._view_raised__3SCFL div [class*="--selected-range-start"][class*="--selected-range-end"],
._view_raised__3SCFL
  div
  [class*="--selected-range-start"][class*="--selected-range-end"]:hover {
  border-radius: 50% !important;
  background-color: var(--color0);
  color: var(--color100);
}
._view_raised__3SCFL div[class*="--hovered-range-start"]:not([class*="--selected"]) {
  border-radius: 99px 8px 8px 99px !important;
  box-shadow: inset 0 0 0 1px var(--color30);
  background-color: var(--color0);
}
._view_raised__3SCFL div[class*="--hovered-range-end"]:not([class*="--selected"]) {
  border-radius: 8px 99px 99px 8px !important;
  box-shadow: inset 0 0 0 1px var(--color30);
  background-color: var(--color0);
}

._view_raised__3SCFL div[class*="--selected-range"][aria-selected="false"] {
  background-color: var(--color5);
}
._view_raised__3SCFL div[class*="--selected-range"][aria-selected="false"]:hover {
  background-color: var(--color10);
}
._view_raised__3SCFL
  div[class*="--hovered-range"][aria-selected="false"]:not([class*="range-start"]):not([class*="range-end"]) {
  background-color: var(--color5) !important;
}
._view_raised__3SCFL div[class*="DayPicker"][aria-selected="false"]:focus {
  background: transparent;
  color: var(--color100);
}
/**/

/*shortcut*/
._view_raised__3SCFL ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"] {
  color: var(--color70);
}
._view_raised__3SCFL
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"],
._view_raised__3SCFL ul[class*="daterangepicker-shortcuts"] a[class*="menu-item"]:hover {
  background: var(--color0);
  box-shadow: var(--shadow4dp);
}
._view_raised__3SCFL
  ul[class*="daterangepicker-shortcuts"]
  a[class*="menu-item"][class*="active"] {
  font-weight: 500;
}
/*end*/

/*navbar*/
._view_raised__3SCFL div[class*="datepicker-navbar"] > button {
  width: 2.5rem;
  border-radius: 50%;
  color: var(--color70);
}
._view_raised__3SCFL div[class*="datepicker-navbar"] > button:hover {
  color: var(--color100);
  box-shadow: var(--shadow2dp);
}
/*end*/

/*month selector*/
._view_raised__3SCFL div[class*="datepicker-month-select"] > select,
._view_raised__3SCFL div[class*="datepicker-year-select"] > select {
  color: var(--color70);
}
._view_raised__3SCFL div[class*="datepicker-month-select"] > select:hover,
._view_raised__3SCFL div[class*="datepicker-year-select"] > select:hover {
  background: var(--color5);
  color: var(--color100);
}
._view_raised__3SCFL div[class*="datepicker-month-select"] > span[class*="icon"],
._view_raised__3SCFL div[class*="datepicker-year-select"] > span[class*="icon"] {
  color: var(--color70);
}
/*end*/

/*footer*/
._view_raised__3SCFL div[class*="datepicker-footer"] > button:hover {
  background: var(--color0);
  box-shadow: var(--shadow4dp);
}
/*end*/

._view_raised__3SCFL div[class*="DayPicker-Day--outside"] > div[class*="day-wrapper"] {
  color: var(--color40);
}
._view_raised__3SCFL div[class*="DayPicker-Day"]:hover {
  background: var(--color0);
}
/* END STYLE */

/**CARD*/

._type_def__f6LZv {
  border-radius: 0.5rem;
  padding: 0;
}

/*CARD*/
/*filled*/
._view_filled__2ElC4 {
  background-color: var(--color50);
  border: none;
  box-shadow: var(--shadow2dp);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_filled__2ElC4[class*="interactive"]:hover {
  box-shadow: var(--shadow8dp);
  background-color: var(--color70);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_filled__2ElC4[class*="interactive"]:active {
  box-shadow: var(--shadow2dp);
  background-color: var(--color50);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

/*smooth*/
._view_smooth__1nYmQ {
  background-color: var(--color0);
  border: none;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_smooth__1nYmQ[class*="interactive"]:hover {
  background-color: var(--color10);
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_smooth__1nYmQ[class*="interactive"]:active {
  box-shadow: none;
  background-color: var(--color0);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

/*outlined*/
._view_outlined__VwxNv {
  background-color: var(--white);
  border: 1px solid var(--color20);
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_outlined__VwxNv[class*="interactive"]:hover {
  border: 1px solid var(--color30);
  box-shadow: 0 0 0 1px var(--color30);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_outlined__VwxNv[class*="interactive"]:active {
  box-shadow: none;
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
/*raised*/
._view_raised__M7D7f {
  background-color: var(--white);
  box-shadow: var(--shadow2dp);
  border: none;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_raised__M7D7f[class*="interactive"]:hover {
  box-shadow: var(--shadow8dp);
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}
._view_raised__M7D7f[class*="interactive"]:active {
  box-shadow: var(--shadow2dp);
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 1, 0.75, 0.9);
}

/* CHIPS STYLES*/

._type_def__3pS9J[class*="tag"] {
  min-width: 0px;
  height: 2rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5rem;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
  text-transform: none;
  padding: 0.25rem 0.625rem;
  justify-content: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
._type_def__3pS9J[class*="tag"]:focus,
._type_dense__20JLD[class*="tag"]:focus {
  outline: none !important;
}

._type_def__3pS9J[class*="tag"] span[icon]:first-of-type {
  margin-left: 0;
  margin-right: 0.375rem;
}
._type_def__3pS9J[class*="tag"] span[icon]:last-of-type {
  margin-right: 0;
  margin-left: 0.375rem;
}
._type_def__3pS9J[class*="tag"] span[icon]:first-of-type > svg {
  height: 1.25rem;
  width: 1.25rem;
}
._type_def__3pS9J[class*="tag"] span[class*="icon-tick"]:first-of-type {
  width: 0;
  height: 0;
  margin-right: 0;
  transform: scale(0);
  transition: all 200ms ease-in;
}
._type_def__3pS9J._type_active__2nHMt[class*="tag"] span[class*="icon-tick"]:first-of-type {
  height: 1.25rem;
  width: 1.25rem;
  margin-right: 0.375rem;
  transform: scaleX(1);
  transition: all 200ms ease-in;
}
._type_def__3pS9J[class*="tag"] button[class*="tag-remove"] > span[class*="small-cross"] {
  margin-right: 0;
  margin-left: 0.375rem;
}
._type_def__3pS9J[class*="tag"] button[class*="tag-remove"] {
  padding: 0;
  padding-right: 0;
  margin-right: 0 !important;
}
._type_def__3pS9J[class*="tag"] span[class*="text"] {
  margin-right: 0;
}
/*dense*/
._type_dense__20JLD[class*="tag"] {
  min-width: 0px;
  height: 1.25rem;
  min-height: 1.25rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1rem;
  font-size: var(--font_size_caption);
  letter-spacing: var(--letter_spacing_caption);
  text-transform: none;
  padding: 0.125rem 0.375rem;
  justify-content: left;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
._type_dense__20JLD[class*="tag"] span[icon]:first-of-type {
  margin-left: 0;
  margin-right: 0.25rem;
}
._type_dense__20JLD[class*="tag"] span[icon]:last-of-type {
  margin-right: 0;
  margin-left: 0.25rem;
}
._type_dense__20JLD[class*="tag"] span[icon]:first-of-type > svg {
  height: 1rem;
  width: 1rem;
}
._type_dense__20JLD[class*="tag"] span[class*="icon-tick"]:first-of-type {
  width: 0;
  height: 0;
  margin-right: 0;
  transform: scale(0);
  transition: all 200ms ease-in;
}
._type_dense__20JLD._type_active__2nHMt[class*="tag"] span[class*="icon-tick"]:first-of-type {
  height: 1rem;
  width: 1rem;
  margin-right: 0.25rem;
  transform: scaleX(1);
  transition: all 200ms ease-in;
}
._type_dense__20JLD[class*="tag"] button[class*="tag-remove"] > span[class*="small-cross"] {
  margin-right: 0;
  margin-left: 0.125rem;
}
._type_dense__20JLD[class*="tag"] button[class*="tag-remove"] {
  padding: 0;
  padding-right: 0;
  margin-right: 0 !important;
}
._type_dense__20JLD[class*="tag"] span[class*="text"] {
  margin-right: 0;
}
/*END dense*/
/*ROUNDED*/
._type_round__1iS54[class*="tag"] {
  border-radius: 99px;
}
/*-----------------------*/

/*WITH NUMBER */
._type_def__3pS9J[class*="tag"]._type_number__2CIEj,
._type_dense__20JLD[class*="tag"]._type_number__2CIEj {
  line-height: 1.25rem;
}
._type_def__3pS9J[class*="tag"]._type_number__2CIEj span > div {
  min-width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  border-radius: 0.25rem;
  text-align: center;
  margin-right: 0.375rem;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_medium);
  padding: 0 0.4375rem;
}
._type_def__3pS9J[class*="tag"]._type_number__2CIEj._type_round__1iS54 span > div {
  border-radius: 99px;
}

._type_dense__20JLD[class*="tag"]._type_number__2CIEj span > div {
  min-width: 0;
  height: 1.25rem;
  display: inline-block;
  text-align: center;
  margin-right: 0.25rem;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_medium);
  padding: 0 0;
  line-height: 1.25rem;
}

/*-----------*/

/*WITH AVATAR*/
._type_def__3pS9J[class*="tag"]._type_avatar__33pqm,
._type_dense__20JLD[class*="tag"]._type_avatar__33pqm {
  border-radius: 99px;
}
._type_dense__20JLD[class*="tag"]._type_avatar__33pqm {
  padding-left: 0;
}
._type_def__3pS9J[class*="tag"]._type_avatar__33pqm span[class*="text"],
._type_dense__20JLD[class*="tag"]._type_avatar__33pqm span[class*="text"] {
  line-height: 0;
}
._type_def__3pS9J[class*="tag"]._type_avatar__33pqm span > div:first-of-type,
._type_dense__20JLD[class*="tag"]._type_avatar__33pqm span > div:first-of-type {
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  text-align: center;
  margin-right: 0.375rem;
  vertical-align: middle;
}
._type_dense__20JLD[class*="tag"]._type_avatar__33pqm span > div:first-of-type {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.25rem;
}
._type_def__3pS9J[class*="tag"]._type_avatar__33pqm span > div:first-of-type > img {
  width: 1.5rem;
  height: 1.5rem;
}
._type_dense__20JLD[class*="tag"]._type_avatar__33pqm span > div:first-of-type > img {
  width: 1.25rem;
  height: 1.25rem;
}
._type_dense__20JLD[class*="tag"]._type_avatar__33pqm._type_outlined__1ERs9 span > div:first-of-type > img {
  width: 1.125rem;
  height: 1.125rem;
  margin-top: 0.0625rem;
}
._type_def__3pS9J[class*="tag"]._type_avatar__33pqm span > div:last-of-type,
._type_dense__20JLD[class*="tag"]._type_avatar__33pqm span > div:last-of-type {
  display: inline-block;
  vertical-align: middle;
}
/**END AVATAR*/

/* CHIPS VIEW STYLES*/

/*SMOOTH STYLE*/
._view_smooth__271nm[class*="tag"],
._view_smooth__271nm[class*="tag"]:visited {
  color: var(--color70);
  background-color: var(--color5);
  transition: all 180ms ease-in;
}
._view_smooth__271nm[class*="tag"]:hover {
  box-shadow: var(--shadow2dp);
  background-color: var(--color5);
  transition: all 200ms ease-in;
}
._view_smooth__271nm[class*="tag"] svg {
  color: var(--color40);
}
._view_smooth__271nm[class*="tag"] button[class*="tag-remove"] {
  opacity: 1;
}
._view_smooth__271nm[class*="tag"]
  button[class*="tag-remove"]
  > span[class*="small-cross"]
  > svg {
  color: var(--color50);
}
._view_smooth__271nm[class*="tag"]
  button[class*="tag-remove"]:hover
  > span[class*="small-cross"]
  > svg {
  color: var(--color70);
  opacity: 1;
  transition: all 180ms ease-in;
}
._view_smooth__271nm[class*="tag"]._view_active__3sbTF {
  color: var(--color0);
  background-color: var(--color40);
  transition: all 180ms ease-in;
}
._view_smooth__271nm[class*="tag"]._view_active__3sbTF svg,
._view_smooth__271nm[class*="tag"]._view_active__3sbTF svg:hover {
  background-color: transparent;
  color: var(--color0);
}

._view_smooth__271nm[class*="tag"]._view_disabled__34Csq,
._view_smooth__271nm[class*="tag"]._view_disabled__34Csq svg,
._view_smooth__271nm[class*="tag"]._view_disabled__34Csq._view_active__3sbTF,
._view_smooth__271nm[class*="tag"]._view_disabled__34Csq._view_active__3sbTF svg,
._view_smooth__271nm[class*="tag"]._view_disabled__34Csq:hover,
._view_smooth__271nm[class*="tag"]._view_disabled__34Csq
  button[class*="tag-remove"]:hover
  > span[class*="small-cross"]
  > svg,
._view_smooth__271nm[class*="tag"]._view_disabled__34Csq
  button[class*="tag-remove"]
  > span[class*="small-cross"]
  > svg,
._view_smooth__271nm[class*="tag"]._view_disabled__34Csq
  button[class*="tag-remove"]:hover
  > span[class*="small-cross"]
  > svg,
._view_smooth__271nm[class*="tag"]._view_disabled__34Csq button[class*="tag-remove"],
._view_smooth__271nm[class*="tag"]._view_disabled__34Csq button[class*="tag-remove"]:hover {
  background-color: var(--color5);
  color: var(--color30);
  box-shadow: none;
  cursor: no-drop;
}

/* END STYLE */

/*OUTLINED STYLE*/
._view_outlined__2QkAk[class*="tag"],
._view_outlined__2QkAk[class*="tag"]:visited {
  color: var(--color70);
  background-color: var(--white);
  border: 0.0625rem solid var(--color30);
  transition: all 100ms ease-in;
}
._view_outlined__2QkAk[class*="tag"]:hover {
  box-shadow: 0 0 0 1px var(--color30);
  background-color: var(--white);
  border: 0.0625rem solid var(--color30);
  transition: all 200ms ease-in;
}
._view_outlined__2QkAk[class*="tag"]._view_active__3sbTF {
  background-color: var(--color5);
  border: 0.0625rem solid var(--color50);
  box-shadow: 0 0 0 1px var(--color50);
  color: var(--color70);
  transition: all 150ms ease-in;
}
._view_outlined__2QkAk[class*="tag"]._view_active__3sbTF svg,
._view_outlined__2QkAk[class*="tag"]._view_active__3sbTF svg:hover {
  color: var(--color70);
  transition: all 150ms ease-in;
}

._view_outlined__2QkAk[class*="tag"] button[class*="tag-remove"] {
  background: transparent;
  opacity: 1;
  transition: all 150ms ease-in;
}
._view_outlined__2QkAk[class*="tag"] svg {
  color: var(--color30);
  transition: all 150ms ease-in;
}
._view_outlined__2QkAk[class*="tag"] button[class*="tag-remove"] > span > svg {
  color: var(--color50);
}

._view_outlined__2QkAk[class*="tag"] button[class*="tag-remove"]:hover > span > svg {
  color: var(--color70);
  transition: all 180ms ease-in;
}

._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq svg,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq._view_active__3sbTF,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq._view_active__3sbTF svg,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq:hover,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq svg:hover,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq button[class*="tag-remove"] > span > svg,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq
  button[class*="tag-remove"]:hover
  > span
  > svg {
  background-color: transparent;
  color: var(--color30);
  border: 0.0625rem solid var(--color10);
  box-shadow: none;
  cursor: no-drop;
  opacity: 1;
}
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq button[class*="tag-remove"] > span > svg,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq button[class*="tag-remove"]:hover > span > svg,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq svg:hover,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq svg,
._view_outlined__2QkAk[class*="tag"]._view_disabled__34Csq._view_active__3sbTF svg {
  border: none;
}
/* END STYLE */
/*WITH NUMBER*/
._view_smooth__271nm[class*="tag"]._view_number__2axTF span > div,
._view_outlined__2QkAk[class*="tag"]._view_number__2axTF span > div {
  background: var(--color70);
  color: var(--color0);
}
._view_smooth__271nm[class*="tag"]._view_number__2axTF._view_dense__21Qa6 span > div,
._view_outlined__2QkAk[class*="tag"]._view_number__2axTF._view_dense__21Qa6 span > div {
  background: none;
  color: var(--color100);
}
._view_smooth__271nm[class*="tag"]._view_number__2axTF._view_disabled__34Csq span > div,
._view_outlined__2QkAk[class*="tag"]._view_number__2axTF._view_disabled__34Csq span > div {
  background: var(--color50);
}
._view_smooth__271nm[class*="tag"]._view_number__2axTF._view_disabled__34Csq._view_dense__21Qa6 span > div,
._view_outlined__2QkAk[class*="tag"]._view_number__2axTF._view_disabled__34Csq._view_dense__21Qa6 span > div {
  color: var(--color70);
  background: none;
}

/*.active,
.active svg {
  color: var(--base-color, var(--prim-color)) !important;
}*/
._view_default__3PDAE._view_checked__mYO-8 svg {
  color: var(--prim-color) !important;
  color: var(--base-color, var(--prim-color)) !important;
}
._view_default__3PDAE._view_checked__mYO-8 svg {
  color: var(--prim-color) !important;
  color: var(--base-color, var(--prim-color)) !important;
}

._view_default__3PDAE span,
._view_default__3PDAE svg {
  color: var(--grey40) !important;
}
._view_default__3PDAE span:hover svg {
  color: var(--prim-color) !important;
  color: var(--base-color, var(--prim-color)) !important;
}

.paper_def__3Cy86 {
  border-radius: 4px !important;
  /* height: 100% !important;*/
  display: inline-block !important;
}

._view_flat__1fd4o {
  box-shadow: none !important;
  background: var(--white) !important;
}

._view_raised__167WX {
  box-shadow: var(--raised-prim-shadow-active)
   !important;
  box-shadow: var(
    --raised-shadow-active,
    var(--raised-prim-shadow-active)
  ) !important;
  background: var(--white) !important;
}

._view_smooth__2QU9a {
  /* background: var(--smooth-bg-active, var(--smooth-prim-bg-active)) !important;
  opacity: 0.48 !important;*/
  box-shadow: none !important;
  background-color: var(--white) !important;
}

._view_filled__1Gd1u {
  /* background: var(--filled-bg-active, var(--filled-prim-bg-active)) !important;*/
  /* opacity: 0.72 !important;*/
  box-shadow: none !important;
  background-color: var(--white) !important;
  /*border-radius: 4px !important;*/
}

._view_ghost__2180g {
  box-shadow: none !important;
  background: var(--white) !important;
  border: 1px solid var(--ghost-prim-border) !important;
  border: 1px solid var(--ghost-border, var(--ghost-prim-border)) !important;
}

.table_def__1RsYB tr {
  height: 40px !important;
}
.table_def__1RsYB th,
.table_dense__2lI5T th {
  padding: 4px 8px 4px 8px !important;
  /*padding-top: 9px !important;*/
  font-family: var(--font-primary) !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  letter-spacing: 0.8px !important;
  /*opacity: 0.64 !important;*/
  border-bottom: 2px solid !important;
}
.table_def__1RsYB td,
.table_dense__2lI5T td {
  padding: 4px 8px 4px 8px !important;
  font-family: var(--font-primary) !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.44px !important;
}

.table_dense__2lI5T tr {
  height: 24px !important;
}
.table_dense__2lI5T th {
  text-transform: uppercase !important;
  font-size: 10px !important;
  line-height: 16px !important;
  letter-spacing: 1.5px !important;
  border-bottom: 1px solid !important;
}
.table_dense__2lI5T td {
  height: 20px;
  font-size: 12px !important;
  line-height: 16px !important;
  letter-spacing: 0.4px !important;
  padding: 2px 8px 2px 8px !important;
}
.table_dense__2lI5T svg {
  height: 20px;
  width: 20px;
}

/*PAGINATION*/
.table_def-pag__2DWL8 div,
.table_def-pag__2DWL8,
.table_def-pag__2DWL8 span {
  height: 40px !important;
  min-height: 0px !important;
  font-family: var(--font-primary) !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 40px !important;
  letter-spacing: 0.25px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  /*top: 9px !important;*/
  overflow: visible !important;
  overflow: initial !important;
}

.table_def-pag__2DWL8 button,
.table_def-pag__2DWL8 button span {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  width: 28px !important;
  height: 28px !important;
}
.table_def-pag__2DWL8 button {
  margin-right: 8px !important;
}
.table_def-pag__2DWL8 svg {
  top: 7px !important;
}
.table_def-pag__2DWL8 div > div > div {
  padding-right: 24px !important;
  min-width: 0px !important;
}
.table_def-pag__2DWL8 div div:nth-of-type(3) {
  margin-left: 16px !important;
}
.table_def-pag__2DWL8 div > div {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.table_def-pag__2DWL8 div:nth-child(1) {
  float: right;
}

/*DENSE PAGINATION*/
.table_dense-pag__3wnST div,
.table_dense-pag__3wnST,
.table_dense-pag__3wnST span {
  height: 24px !important;
  min-height: 0px !important;
  font-family: var(--font-primary) !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  letter-spacing: 0.4px !important;
  overflow: visible !important;
  overflow: initial !important;
}
.table_dense-pag__3wnST p {
  font-size: 12px !important;
}
.table_dense-pag__3wnST div > span {
  vertical-align: middle;
  display: inline-block;
  line-height: 24px;
}
.table_dense-pag__3wnST button,
.table_dense-pag__3wnST button span {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  width: 24px !important;
  height: 24px !important;
}
.table_dense-pag__3wnST button {
  margin-right: 8px !important;
}
.table_dense-pag__3wnST svg {
  top: 0px !important;
}
.table_dense-pag__3wnST div > div > div {
  padding-right: 24px !important;
  padding-left: 8px !important;
  min-width: 0px !important;
}
.table_dense-pag__3wnST div div:nth-of-type(3) {
  margin-left: 16px !important;
}
.table_dense-pag__3wnST div > div {
  margin-right: 0px !important;
  margin-left: 0px !important;
  padding: 0 0 0 0 !important;
  vertical-align: middle;
  display: inline-block;
  line-height: 24px;
}
.table_dense-pag__3wnST div:nth-child(1) {
  float: right;
  width: auto;
}

/*TRASh BUTTON*/
.table_def-trash__1GA_Q {
  font-family: var(--font-primary) !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16px !important;
  /*line-height: 24px !important;*/
  letter-spacing: 0.44px !important;
  display: flex;
}
.table_def-trash__1GA_Q button + span {
  padding-left: 8px;
}
.table_def-trash-btn__23kMq {
  padding: 8px !important;
  width: 40px !important;
  height: 40px !important;
}

.table_dense-trash__1OQR3 {
  font-family: var(--font-primary) !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 12px !important;
  /*line-height: 24px !important;*/
  letter-spacing: 0.44px !important;
  display: flex;
}
.table_dense-trash__1OQR3 button + span {
  padding-left: 8px;
}
.table_dense-trash-btn__2r2T5 {
  vertical-align: baseline;
  vertical-align: initial;
  line-height: 22px;
  margin-left: 2px;
  min-height: 0;
  padding: 0;
}

div[role="document"] {
  margin-top: 0 !important;
}
ul[role="listbox"] {
  padding: 0;
}

.table_info-text__3JlTu {
  margin-bottom: auto;
  margin-top: auto;
}

._view_checkbox__1urc0 {
  padding: 0 !important;
  background-color: transparent !important;
  color: unset !important;
}

li[role="option"] {
  background: transparent !important;
}
li[role="option"]:hover {
  background: linear-gradient(
    90deg,
    var(--gradient-prim-reverse)) !important;
  background: linear-gradient(
    90deg,
    var(--gradGreyRev, var(--gradient-prim-reverse))
  ) !important;
  color: var(--white) !important;
}
/*checkbox*/
._view_flat__1Q7b6 tr[aria-checked="false"]:hover svg,
._view_flat-head-unchecked__1lm2h,
._view_raised__3qHYf tr[aria-checked="false"]:hover svg,
._view_raised-head-unchecked__2CLTM,
._view_smooth__18nRu tr[aria-checked="false"]:hover svg,
._view_smooth-head-unchecked__332D5 {
  color: var(--flat-def-color-table) !important;
  color: var(--flat-color-table, var(--flat-def-color-table)) !important;
}
._view_flat-head-checked__hDyad svg,
._view_smooth-head-checked__2O2HL svg,
._view_raised-head-checked__2SMsX svg {
  color: var(--color100) !important;
}
._view_flat-body-checked__3d9Zx svg,
._view_smooth-body-checked__1G4kH svg,
._view_raised-body-checked__39Gh5 svg {
  color: var(--flat-def-color-checkbox) !important;
  color: var(--flat-color-checkbox, var(--flat-def-color-checkbox)) !important;
}
._view_flat-body-unchecked__O1slI svg,
._view_flat-body-unchecked__O1slI span,
._view_flat-body-checked__3d9Zx span,
._view_smooth-body-unchecked__2_E0y svg,
._view_smooth-body-unchecked__2_E0y span,
._view_smooth-body-checked__1G4kH span,
._view_raised-body-unchecked__2qON6 svg,
._view_raised-body-unchecked__2qON6 span,
._view_raised-body-checked__39Gh5 span {
  color: var(--grey30) !important;
}
._view_filled__3T5jv tr[aria-checked="false"]:hover svg,
._view_filled-head-unchecked__l2fdM,
._view_filled-head-checked__1zza7 svg,
._view_filled-body-checked__ijenO svg,
._view_filled-body-checked__ijenO span {
  color: var(--white) !important;
}
._view_filled-body-unchecked__2ztj3 svg,
._view_filled-body-unchecked__2ztj3 span {
  color: var(--filled-def-color-checkbox)
   !important;
  color: var(
    --filled-color-checkbox,
    var(--filled-def-color-checkbox)
  ) !important;
}

/*FLAT*/
._view_flat__1Q7b6 th {
  color: var(--flat-def-color-table) !important;
  color: var(--flat-color-table, var(--flat-def-color-table)) !important;
}
._view_flat__1Q7b6 thead th {
  border-bottom-color: var(--flat-def-border2-table)
   !important;
  border-bottom-color: var(
    --flat-border2-table,
    var(--flat-def-border2-table)
  ) !important;
}
._view_flat__1Q7b6 td {
  border-bottom: 1px solid
    var(--flat-def-border-table) !important;
  border-bottom: 1px solid
    var(--flat-border-table, var(--flat-def-border-table)) !important;
  color: var(--grey100) !important;
}
._view_flat__1Q7b6 tbody > tr:hover {
  background-color: var(--grey0) !important;
}
._view_flat__1Q7b6 tbody > tr:last-of-type > td {
  border-bottom: 1px solid
    var(--flat-def-borderlast-table) !important;
  border-bottom: 1px solid
    var(--flat-borderlast-table, var(--flat-def-borderlast-table)) !important;
}
._view_dense__2dp4v._view_flat__1Q7b6 td {
  border-bottom: 1px solid
    var(--flat-def-bg-table-hover);
  border-bottom: 1px solid
    var(--flat-bg-table-hover, var(--flat-def-bg-table-hover));
  color: var(--grey100) !important;
}

._view_flat__1Q7b6 tr[aria-checked="true"] td,
._view_flat__1Q7b6 tr[aria-checked="true"]:hover td {
  background: var(--flat-def-bg-table-hover)
   !important;
  background: var(
    --flat-bg-table-hover,
    var(--flat-def-bg-table-hover)
  ) !important;
  border-bottom: 1px solid
    var(--flat-def-borderlast-table) !important;
  border-bottom: 1px solid
    var(--flat-borderlast-table, var(--flat-def-borderlast-table)) !important;
}

/*FILLED*/
._view_filled__3T5jv thead {
  background-color: var(--filled-def-bg-head-table)
   !important;
  background-color: var(
    --filled-bg-head-table,
    var(--filled-def-bg-head-table)
  ) !important;
  border-radius: 4px !important;
}
._view_filled__3T5jv thead th:last-of-type {
  border-radius: 0px 4px 0px 0px !important;
}
._view_filled__3T5jv thead th:first-of-type {
  border-radius: 4px 0px 0px 0px !important;
}
._view_filled__3T5jv th {
  color: var(--white) !important;
}
._view_filled__3T5jv thead span:hover {
  color: var(--white) !important;
}
._view_filled__3T5jv td {
  color: var(--white) !important;
}
._view_filled__3T5jv tr > td {
  border-bottom: 1px solid
    var(--filled-def-border-table) !important;
  border-bottom: 1px solid
    var(--filled-border-table, var(--filled-def-border-table)) !important;
  /*color: var(--white) !important;*/
}
._view_filled__3T5jv tr:last-of-type > td {
  border-bottom: 1px solid
    var(--filled-def-border-table) !important;
  border-bottom: 1px solid
    var(--filled-border-table, var(--filled-def-border-table)) !important;
  /*color: var(--white) !important;*/
}
._view_filled__3T5jv tbody > tr:hover td {
  border-bottom: 1px solid
    var(--filled-def-border-table) !important;
  border-bottom: 1px solid
    var(--filled-border-table, var(--filled-def-border-table)) !important;
  /*border-top: 1px solid
    var(--filled-border-table, var(--filled-def-border-table)) !important;*/
}
._view_filled__3T5jv thead th {
  border-bottom-color: var(--filled-def-border2-table)
   !important;
  border-bottom-color: var(
    --filled-border2-table,
    var(--filled-def-border2-table)
  ) !important;
}
._view_filled__3T5jv tbody > tr:hover {
  background-color: var(--filled-def-bg-table-hover)
   !important;
  background-color: var(
    --filled-bg-table-hover,
    var(--filled-def-bg-table-hover)
  ) !important;
}
._view_filled__3T5jv tbody > tr {
  background-color: var(--filled-def-bg-table)
   !important;
  background-color: var(
    --filled-bg-table,
    var(--filled-def-bg-table)
  ) !important;
}
._view_filled__3T5jv tr[aria-checked="true"] td,
._view_filled__3T5jv tr[aria-checked="true"]:hover td {
  background-color: var(--filled-def-bg-table-hover)
   !important;
  background-color: var(
    --filled-bg-table-hover,
    var(--filled-def-bg-table-hover)
  ) !important;
  border-bottom: 1px solid
    var(--filled-def-border-table) !important;
  border-bottom: 1px solid
    var(--filled-border-table, var(--filled-def-border-table)) !important;
}

._view_filled__3T5jv tbody > tr:last-of-type > td,
._view_smooth__18nRu tbody > tr:last-of-type > td,
._view_raised__3qHYf tbody > tr:last-of-type > td {
  /* border: none !important;*/
}
._view_filled__3T5jv tbody > tr:last-of-type > td:last-of-type,
._view_smooth__18nRu tbody > tr:last-of-type > td:last-of-type,
._view_raised__3qHYf tbody > tr:last-of-type > td:last-of-type {
  border-radius: 0 0 4px 0 !important;
}
._view_filled__3T5jv tbody > tr:last-of-type > td:first-of-type,
._view_smooth__18nRu tbody > tr:last-of-type > td:first-of-type,
._view_raised__3qHYf tbody > tr:last-of-type > td:first-of-type {
  border-radius: 0 0 0 4px !important;
}

._view_pagination__AZqEN tbody > tr:last-of-type > td:last-of-type {
  border-radius: 0 !important;
}
._view_pagination__AZqEN tbody > tr:last-of-type > td:first-of-type {
  border-radius: 0 !important;
}
/*SMOOTH*/
._view_smooth__18nRu thead {
  background-color: var(--smooth-def-bg-head-table)
   !important;
  background-color: var(
    --smooth-bg-head-table,
    var(--smooth-def-bg-head-table)
  ) !important;
  border-radius: 4px !important;
}
._view_smooth__18nRu thead th:last-of-type {
  border-radius: 0px 4px 0px 0px !important;
}
._view_smooth__18nRu thead th:first-of-type {
  border-radius: 4px 0px 0px 0px !important;
}
._view_smooth__18nRu thead th {
  border-bottom-color: var(--smooth-def-border2-table)
   !important;
  border-bottom-color: var(
    --smooth-border2-table,
    var(--smooth-def-border2-table)
  ) !important;
  color: var(--smooth-def-color-table) !important;
  color: var(--smooth-color-table, var(--smooth-def-color-table)) !important;
}
._view_smooth__18nRu td {
  border-bottom: 1px solid
    var(--smooth-def-border-table) !important;
  border-bottom: 1px solid
    var(--smooth-border-table, var(--smooth-def-border-table)) !important;
  color: var(--grey100) !important;
}
._view_smooth__18nRu tbody > tr {
  background-color: var(--smooth-def-bg-table)
   !important;
  background-color: var(
    --smooth-bg-table,
    var(--smooth-def-bg-table)
  ) !important;
}
._view_smooth__18nRu tbody > tr:hover td {
  background-color: var(--white) !important;
}
._view_smooth__18nRu tr:last-of-type td {
  border-bottom: 1px solid
    var(--smooth-def-border-table) !important;
  border-bottom: 1px solid
    var(--smooth-border-table, var(--smooth-def-border-table)) !important;
}
._view_smooth__18nRu tr[aria-checked="true"] td,
._view_smooth__18nRu tr[aria-checked="true"]:hover td {
  background: var(--smooth-def-bg-active) !important;
  background: var(--smooth-bg-active, var(--smooth-def-bg-active)) !important;
  border-bottom: 1px solid
    var(--smooth-def-border2-table) !important;
  border-bottom: 1px solid
    var(--smooth-border2-table, var(--smooth-def-border2-table)) !important;
}

/*RAISED*/
._view_raised__3qHYf thead {
  background-color: var(--white) !important;
  border-radius: 4px !important;
}
._view_raised__3qHYf thead th:last-of-type {
  border-radius: 0px 4px 0px 0px !important;
}
._view_raised__3qHYf thead th:first-of-type {
  border-radius: 4px 0px 0px 0px !important;
}
._view_raised__3qHYf thead th {
  border-bottom-color: var(--raised-def-border2-table)
   !important;
  border-bottom-color: var(
    --raised-border2-table,
    var(--raised-def-border2-table)
  ) !important;
  color: var(--raised-def-color-table) !important;
  color: var(--raised-color-table, var(--raised-def-color-table)) !important;
}
._view_raised__3qHYf td {
  border-bottom: 1px solid
    var(--raised-def-border-table) !important;
  border-bottom: 1px solid
    var(--raised-border-table, var(--raised-def-border-table)) !important;
  color: var(--grey100) !important;
}
._view_dense__2dp4v._view_raised__3qHYf td {
  border-bottom: 1px solid
    var(--flat-def-bg-table-hover);
  border-bottom: 1px solid
    var(--flat-bg-table-hover, var(--flat-def-bg-table-hover));
  color: var(--grey100) !important;
}
._view_raised__3qHYf tbody > tr {
  background-color: var(--white) !important;
}
._view_raised__3qHYf tbody > tr:hover {
  background-color: var(--grey0) !important;
}
._view_raised__3qHYf tr:last-of-type td {
  border-bottom: 1px solid
    var(--raised-def-border-table) !important;
  border-bottom: 1px solid
    var(--raised-border-table, var(--raised-def-border-table)) !important;
}
._view_dense__2dp4v._view_raised__3qHYf tr:last-of-type td {
  border-bottom: 1px solid
    var(--flat-def-bg-table-hover);
  border-bottom: 1px solid
    var(--flat-bg-table-hover, var(--flat-def-bg-table-hover));
}
._view_raised__3qHYf tr[aria-checked="true"] td,
._view_raised__3qHYf tr[aria-checked="true"]:hover td {
  background: var(--smooth-def-bg-table) !important;
  background: var(--smooth-bg-table, var(--smooth-def-bg-table)) !important;
  border-bottom: 1px solid
    var(--raised-def-border2-table) !important;
  border-bottom: 1px solid
    var(--raised-border2-table, var(--raised-def-border2-table)) !important;
}

._view_flat__1Q7b6 thead span:hover,
._view_smooth__18nRu thead span:hover,
._view_raised__3qHYf thead span:hover {
  color: var(--color100) !important;
}

._view_flat__1Q7b6 tr:hover td,
._view_smooth__18nRu tr:hover td,
._view_raised__3qHYf tr:hover td {
  border-bottom: 1px solid
    var(--raised-def-border-table) !important;
  border-bottom: 1px solid
    var(--raised-border-table, var(--raised-def-border-table)) !important;
}

/*SORTING STYLE*/
/*Smooth, raised, flat*/
._view_smooth__18nRu._view_sorted_head__TF4GN,
._view_flat__1Q7b6._view_sorted_head__TF4GN,
._view_raised__3qHYf._view_sorted_head__TF4GN {
  background: var(--grey0) !important;
  border-bottom: 2px solid var(--smooth-def-bg-active) !important;
  border-bottom: 2px solid var(--smooth-bg-active, var(--smooth-def-bg-active)) !important;
}
._view_dense_smooth__1RoxA._view_sorted_head__TF4GN,
._view_dense_flat__3UHuW._view_sorted_head__TF4GN,
._view_dense_raised__2Oz3t._view_sorted_head__TF4GN {
  background: var(--grey0) !important;
  border-bottom: 1px solid var(--smooth-def-bg-active) !important;
  border-bottom: 1px solid var(--smooth-bg-active, var(--smooth-def-bg-active)) !important;
}
._view_smooth__18nRu._view_sorted_body__1vEn7,
._view_flat__1Q7b6._view_sorted_body__1vEn7,
._view_raised__3qHYf._view_sorted_body__1vEn7 {
  background: var(--grey0) !important;
  border-color: var(--grey0) !important;
  border-radius: 0px !important;
}
._view_dense_smooth__1RoxA._view_sorted_body__1vEn7,
._view_dense_flat__3UHuW._view_sorted_body__1vEn7,
._view_dense_raised__2Oz3t._view_sorted_body__1vEn7 {
  background: var(--grey0) !important;
  border-bottom: 1px solid var(--grey0) !important;
  border-radius: 0px !important;
}

._view_smooth__18nRu._view_sorted_head__TF4GN span,
._view_flat__1Q7b6._view_sorted_head__TF4GN span,
._view_raised__3qHYf._view_sorted_head__TF4GN span,
._view_dense_smooth__1RoxA._view_sorted_head__TF4GN span,
._view_dense_flat__3UHuW._view_sorted_head__TF4GN span,
._view_dense_raised__2Oz3t._view_sorted_head__TF4GN span {
  color: var(--color100) !important;
}
._view_smooth__18nRu._view_sorted_head__TF4GN svg,
._view_flat__1Q7b6._view_sorted_head__TF4GN svg,
._view_raised__3qHYf._view_sorted_head__TF4GN svg,
._view_dense_smooth__1RoxA._view_sorted_head__TF4GN svg,
._view_dense_flat__3UHuW._view_sorted_head__TF4GN svg,
._view_dense_raised__2Oz3t._view_sorted_head__TF4GN svg {
  color: var(--color100) !important;
}
/*Filled*/
._view_filled__3T5jv._view_sorted_head__TF4GN {
  background: var(--filled-def-head-table-sort)
   !important;
  background: var(
    --filled-head-table-sort,
    var(--filled-def-head-table-sort)
  ) !important;
  border-bottom: 2px solid var(--filled-def-bg-table) !important;
  border-bottom: 2px solid var(--filled-bg-table, var(--filled-def-bg-table)) !important;
}
._view_dense_filled__1znGK._view_sorted_head__TF4GN {
  background: var(--filled-def-head-table-sort)
   !important;
  background: var(
    --filled-head-table-sort,
    var(--filled-def-head-table-sort)
  ) !important;
  border-bottom: 1px solid var(--filled-def-bg-table) !important;
  border-bottom: 1px solid var(--filled-bg-table, var(--filled-def-bg-table)) !important;
}
._view_filled__3T5jv._view_sorted_head__TF4GN span,
._view_dense_filled__1znGK._view_sorted_head__TF4GN span {
  color: var(--white) !important;
}
._view_filled__3T5jv._view_sorted_head__TF4GN svg,
._view_dense_filled__1znGK._view_sorted_head__TF4GN svg {
  color: var(--white) !important;
}
._view_filled__3T5jv._view_sorted_body__1vEn7,
._view_dense_filled__1znGK._view_sorted_body__1vEn7 {
  border-color: var(--filled-def-bg-table-hover)
   !important;
  border-color: var(
    --filled-bg-table-hover,
    var(--filled-def-bg-table-hover)
  ) !important;
  background: var(--filled-def-bg-table-hover)
   !important;
  background: var(
    --filled-bg-table-hover,
    var(--filled-def-bg-table-hover)
  ) !important;
  border-radius: 0px !important;
}

/*PAGINATION*/
/*Flat*/
._view_flat-pag__2VNNQ span {
  color: var(--grey60) !important;
}
._view_flat-pag__2VNNQ svg,
._view_flat-pag__2VNNQ button span {
  color: var(--flat-def-color-table-pag)
   !important;
  color: var(
    --flat-color-table-pag,
    var(--flat-def-color-table-pag)
  ) !important;
}
._view_flat-pag__2VNNQ div > div > div {
  color: var(--flat-def-color-table-pag)
   !important;
  color: var(
    --flat-color-table-pag,
    var(--flat-def-color-table-pag)
  ) !important;
}
._view_flat-pag__2VNNQ div > div > div:focus,
._view_filled-pag__zqIua div > div > div:focus,
._view_smooth-pag__3JFEL div > div > div:focus,
._view_raised-pag__BvqjO div > div > div:focus {
  background-color: transparent !important;
}
._view_flat-pag__2VNNQ button:hover {
  background-color: var(--white) !important;
  box-shadow: var(--raised-def-shadow) !important;
  box-shadow: var(--raised-shadow, var(--raised-def-shadow)) !important;
}
/*Filled*/
._view_filled-pag__zqIua {
  background-color: var(--filled-def-bg-table)
   !important;
  background-color: var(
    --filled-bg-table,
    var(--filled-def-bg-table)
  ) !important;
  /* border-top: 1px solid
    var(--filled-border-table, var(--filled-def-border-table)) !important;*/
  border-radius: 0 0 4px 4px !important;
  color: var(--white) !important;
}
._view_filled-pag__zqIua svg {
  color: var(--white) !important;
}
/*Smooth*/
._view_smooth-pag__3JFEL {
  background-color: var(--smooth-def-bg-table)
   !important;
  background-color: var(
    --smooth-bg-table,
    var(--smooth-def-bg-table)
  ) !important;
  /* border-top: 1px solid
    var(--smooth-border-table, var(--smooth-def-border-table)) !important;*/
  border-radius: 0 0 4px 4px !important;
  color: var(--smooth-def-color-table-pag)
   !important;
  color: var(
    --smooth-color-table-pag,
    var(--smooth-def-color-table-pag)
  ) !important;
}
._view_smooth-pag__3JFEL svg {
  color: var(--smooth-def-color-table-pag)
   !important;
  color: var(
    --smooth-color-table-pag,
    var(--smooth-def-color-table-pag)
  ) !important;
}
._view_def__3g90m._view_smooth-pag__3JFEL button {
  background-color: var(--white) !important;
  border: 1px solid var(--color30) !important;
}
/*.dense.smooth-pag button {
  border: none !important;
}*/
._view_smooth-pag__3JFEL button:hover {
  border-color: var(--smooth-def-color-table-pag)
   !important;
  border-color: var(
    --smooth-color-table-pag,
    var(--smooth-def-color-table-pag)
  ) !important;
}
/*Raised*/
._view_raised-pag__BvqjO {
  /* border-top: 1px solid
    var(--raised-border-table, var(--raised-def-border-table)) !important;*/
  color: var(--smooth-def-color-table-pag)
   !important;
  color: var(
    --smooth-color-table-pag,
    var(--smooth-def-color-table-pag)
  ) !important;
}
._view_raised-pag__BvqjO svg {
  color: var(--smooth-def-color-table-pag)
   !important;
  color: var(
    --smooth-color-table-pag,
    var(--smooth-def-color-table-pag)
  ) !important;
}
._view_raised-pag__BvqjO button:hover {
  background-color: var(--color5) !important;
}

/*TRASH BUTTON*/
._view_flat-trash__2EMys,
._view_smooth-trash__1q8gp,
._view_raised-trash__nbeY_ {
  color: var(--filled-def-bg-head-table)
   !important;
  color: var(
    --filled-bg-head-table,
    var(--filled-def-bg-head-table)
  ) !important;
}
._view_flat-trash-btn__9W3dG,
._view_smooth-trash-btn__1Gzbb,
._view_raised-trash-btn__2rgCC {
  color: var(--red50) !important;
}
._view_flat-trash-btn__9W3dG:hover,
._view_smooth-trash-btn__1Gzbb:hover,
._view_raised-trash-btn__2rgCC:hover {
  background-color: transparent !important;
  color: var(--red60) !important;
}

._view_filled-trash__37s8l,
._view_filled-trash-btn__1mQxb {
  color: var(--white) !important;
}

._type_default__31HJs {
  width: 100%;
  margin: 0.5rem 0;
  border-radius: 0.25rem;
}

._type_default__31HJs._type_vertical__R_ahw {
  margin: 0 0.5rem;
}

._view_color0__1NYMG {
  background: var(--color0);
}
._view_color5__1Jojh {
  background: var(--color5);
}
._view_color10__1g2c2 {
  background: var(--color10);
}
._view_color20__3_nmP {
  background: var(--color20);
}
._view_color30__2GHQh {
  background: var(--color30);
}
._view_color40__19eWg {
  background: var(--color40);
}
._view_color50__3avaN {
  background: var(--color50);
}
._view_color60__rSzyh {
  background: var(--color60);
}
._view_color70__3Tdw1 {
  background: var(--color70);
}
._view_color80__1rUb1 {
  background: var(--color80);
}
._view_color90__ojyWO {
  background: var(--color90);
}
._view_color100__2QB7v {
  background: var(--color100);
}

/* TOOLTIP*/
._type_def__2xSHY > div[class*="popover-arrow"] {
  width: 21px;
  height: 21px;
}
._type_def__2xSHY > div[class*="popover-arrow"]::before {
  margin: 3px;
  border-radius: 0;
}

/*TOOLTIP*/

/*FILLED*/
._view_filled__E7xeo div[class*="popover-content"] {
  background: var(--color70);
  color: var(--white);
}
._view_filled__E7xeo div[class*="popover-arrow"] > svg > path:last-of-type {
  fill: var(--color70);
}
._view_filled__E7xeo div[class*="popover-arrow"] > svg > path:first-of-type {
  fill-opacity: 0;
  fill: none;
}
._view_filled__E7xeo > div[class*="popover-arrow"]::before {
  box-shadow: none;
}
._view_filled__E7xeo {
  box-shadow: var(--shadow8dp);
}

/*SMOOTH*/
._view_smooth__1OW5j div[class*="popover-content"] {
  background: var(--color10);
  color: var(--color100);
}
._view_smooth__1OW5j div[class*="popover-arrow"] > svg > path:last-of-type {
  fill: var(--color10);
}
._view_smooth__1OW5j div[class*="popover-arrow"] > svg > path:first-of-type {
  fill-opacity: 0;
  fill: none;
}
._view_smooth__1OW5j > div[class*="popover-arrow"]::before {
  box-shadow: none;
}
._view_smooth__1OW5j {
  box-shadow: none;
}
/*RAISED*/
._view_raised__3WFdx div[class*="popover-content"] {
  background: var(--color0);
  color: var(--color100);
}
._view_raised__3WFdx div[class*="popover-arrow"] > svg > path:last-of-type {
  fill: var(--color0);
}
._view_raised__3WFdx div[class*="popover-arrow"] > svg > path:first-of-type {
  fill-opacity: 0;
  fill: none;
}
._view_raised__3WFdx > div[class*="popover-arrow"]::before,
._view_raised__3WFdx {
  box-shadow: var(--shadow4dp);
}

/*RADIO SHAPE CSS MODULE*/
/*CONTAINER*/
._type_container__1_b-Y {
  align-items: center;
  box-shadow: none;
  border-radius: 99px;
}

/*---------------------------------------*/

._type_def__33w1o,
._type_dense__57_pi {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0;
  display: flex;
}
._type_inline__W2S2i._type_def__33w1o,
._type_inline__W2S2i._type_dense__57_pi {
  display: inline-flex;
}
._type_def__33w1o._type_fill__3tUZj,
._type_dense__57_pi._type_fill__3tUZj {
  width: 100%;
}
._type_def__33w1o {
  height: 2.5rem;
  line-height: 2.5rem;
  font-size: var(--font_size_body);
  letter-spacing: var(--letter_spacing_body);
}
._type_def__33w1o[class*="container"] > label[class*="control"],
._type_dense__57_pi[class*="container"] > label[class*="control"] {
  padding: 0;
  margin: 0;
  display: flex;
  margin-right: 1rem;
}
._type_def__33w1o[class*="container"] > label[class*="control"] > span[class*="indicator"] {
  margin: auto 1.125rem auto 0.625rem;
}
._type_def__33w1o input:checked ~ span[class*="indicator"]::before,
._type_def__33w1o input:not(:checked) ~ span[class*="indicator"]::before {
  height: 0.5rem;
  width: 0.5rem;
  margin: auto;
  margin-top: 0.25rem;
  border-radius: 99px;
}
._type_def__33w1o input:checked ~ div {
  letter-spacing: var(--letter_spacing_body_bold);
  font-weight: var(--font_weight_body_medium);
}
/*Dense*/
._type_dense__57_pi {
  height: 1.75rem;
  line-height: 1.75rem;
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
}

._type_dense__57_pi[class*="container"]
  > label[class*="control"]
  > span[class*="indicator"] {
  margin: auto 0.75rem auto 0.375rem;
}
._type_dense__57_pi input:checked ~ span[class*="indicator"]::before,
._type_dense__57_pi input:not(:checked) ~ span[class*="indicator"]::before {
  height: 0.375rem;
  width: 0.375rem;
  margin: auto;
  margin-top: 0.1875rem;
  border-radius: 99px;
}
._type_dense__57_pi input:checked ~ div {
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: var(--font_weight_body_medium);
}

._type_def__33w1o._type_with_helper__5RQK6 {
  height: 2.75rem;
  line-height: 2.75rem;
}

._type_dense__57_pi._type_with_helper__5RQK6 {
  height: 2.5rem;
  line-height: 2.5rem;
}

/*HELPER TEXT*/
._type_helperBox__2091t {
  line-height: normal;
  line-height: initial;
  margin-top: auto;
  margin-bottom: auto;
}
._type_helperBox__2091t > ._type_helperText__30_tW {
  font-size: var(--font_size_body_small);
  letter-spacing: var(--letter_spacing_body_small);
  font-weight: normal;
}

/*-----------------------------------------*/

/*LEFT*/
._type_leftPosition__1rjVp[class*="container"] > label[class*="control"] {
  flex-direction: row-reverse;
  margin-left: 1rem;
  margin-right: 0;
}
._type_def__33w1o._type_leftPosition__1rjVp[class*="container"]
  > label[class*="control"]
  > span[class*="indicator"] {
  margin: auto 0.625rem auto 1.125rem;
}
._type_dense__57_pi._type_leftPosition__1rjVp[class*="container"]
  > label[class*="control"]
  > span[class*="indicator"] {
  margin: auto 0.375rem auto 0.75rem;
}

/*------------------------*/

/*RTL*/
._type_rtl__TfKj0 > div {
  text-align: right;
}

/*----------------------------------*/

/*RADIO*/

/*FLAT*/
._view_flat__1MW6B {
  color: var(--color100);
}
._view_flat__1MW6B > label[class*="control"] span[class*="indicator"] {
  background-color: transparent;
  background-color: initial;
  background-image: none;
  box-shadow: none;
  border: 2px solid var(--color70);
}
._view_flat__1MW6B:hover:not(._view_disabled__2QzDo)
  > label[class*="control"]
  > span[class*="indicator"] {
  background-color: var(--color5);
  box-shadow: 0 0 0 0.625rem var(--color5);
  transition: box-shadow ease 150ms;
}
._view_flat__1MW6B[class*="dense"]:hover
  > label[class*="control"]
  > span[class*="indicator"],
._view_flat__1MW6B._view_checked__1zgJJ[class*="dense"]
  > label[class*="control"]
  > span[class*="indicator"] {
  box-shadow: 0 0 0 0.375rem var(--color5);
  background-color: var(--color5);
}
._view_flat__1MW6B._view_checked__1zgJJ {
  background: var(--color5);
  transition: background ease 250ms;
}
._view_flat__1MW6B[class*="dense"]._view_checked__1zgJJ {
  background: unset;
  transition: background ease 250ms;
}
._view_flat__1MW6B._view_checked__1zgJJ input:checked ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
  background-color: var(--color70);
  transition: transform ease 250ms, background-color ease 250ms;
}
._view_flat__1MW6B:hover input:checked ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
}
._view_flat__1MW6B input:not(:checked) ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
  background-color: var(--color70);
  transform: scale(0);
  transition: transform ease 150ms;
}
/*Disabled*/
._view_flat__1MW6B._view_disabled__2QzDo > label[class*="control"] > div {
  color: var(--color40);
}
._view_flat__1MW6B._view_checked__1zgJJ._view_disabled__2QzDo > label[class*="control"] > span[class*="indicator"] {
  background-color: var(--color5);
}
._view_flat__1MW6B._view_disabled__2QzDo:hover > label[class*="control"] > span[class*="indicator"] {
  background-color: transparent;
  background-color: initial;
  box-shadow: none;
}
._view_flat__1MW6B[class*="dense"]._view_checked__1zgJJ._view_disabled__2QzDo
  > label[class*="control"]
  > span[class*="indicator"] {
  background-color: var(--color5);
  box-shadow: 0 0 0 0.375rem var(--color5);
}
/*helper*/
._view_flat__1MW6B ._view_helperText__1hKcd,
._view_flat__1MW6B._view_disabled__2QzDo ._view_helperText__1hKcd {
  color: var(--color60);
}
/*-----------*/

/*SMOOTH*/
._view_smooth__3j8MK {
  color: var(--color100);
  background: var(--color0);
}
._view_smooth__3j8MK:hover {
  background: var(--color5);
  transition: background ease 250ms;
}
._view_smooth__3j8MK._view_checked__1zgJJ {
  background: var(--color10);
  transition: background ease 250ms;
}
._view_smooth__3j8MK > label[class*="control"] span[class*="indicator"] {
  background-color: transparent;
  background-color: initial;
  background-image: none;
  box-shadow: none;
  border: 2px solid var(--color70);
}
._view_smooth__3j8MK:hover:not(._view_disabled__2QzDo)
  > label[class*="control"]
  > span[class*="indicator"] {
  background-color: var(--color5);
  box-shadow: none;
  transition: all ease 150ms;
}
._view_smooth__3j8MK._view_checked__1zgJJ > label[class*="control"] > span[class*="indicator"],
._view_smooth__3j8MK._view_checked__1zgJJ:hover > label[class*="control"] > span[class*="indicator"] {
  background-color: var(--color10);
  transition: all ease 150ms;
}

._view_smooth__3j8MK._view_checked__1zgJJ input:checked ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
  background-color: var(--color70);
  transition: transform ease 250ms, background-color ease 250ms;
}
._view_smooth__3j8MK:hover input:checked ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
}
._view_smooth__3j8MK input:not(:checked) ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
  background-color: var(--color70);
  transform: scale(0);
  transition: transform ease 150ms;
}
/*Disabled*/
._view_smooth__3j8MK._view_disabled__2QzDo > label[class*="control"] > div {
  color: var(--color40);
}
._view_smooth__3j8MK._view_checked__1zgJJ._view_disabled__2QzDo,
._view_smooth__3j8MK._view_disabled__2QzDo:hover,
._view_smooth__3j8MK._view_checked__1zgJJ._view_disabled__2QzDo > label[class*="control"] > span[class*="indicator"] {
  background-color: var(--color0);
}
._view_smooth__3j8MK._view_disabled__2QzDo._view_checked__1zgJJ > label[class*="control"] > span[class*="indicator"] {
  background-color: transparent;
  background-color: initial;
  box-shadow: none;
}
/*helper*/
._view_smooth__3j8MK ._view_helperText__1hKcd,
._view_smooth__3j8MK._view_disabled__2QzDo ._view_helperText__1hKcd {
  color: var(--color60);
}
/*-----------*/

/*RAISED*/
._view_raised__Tl-QG {
  color: var(--color100);
  background: var(--color0);
  box-shadow: var(--shadow2dp);
}
._view_raised__Tl-QG:hover {
  background: var(--white);
  box-shadow: var(--shadow4dp);
  transition: background ease 250ms;
}
._view_raised__Tl-QG._view_checked__1zgJJ {
  background: var(--color5);
  box-shadow: none;
  transition: background ease 250ms;
}
._view_raised__Tl-QG > label[class*="control"] span[class*="indicator"] {
  background-color: transparent;
  background-color: initial;
  background-image: none;
  box-shadow: none;
  border: 2px solid var(--color70);
}
._view_raised__Tl-QG:hover:not(._view_disabled__2QzDo)
  > label[class*="control"]
  > span[class*="indicator"] {
  background-color: var(--white);
  box-shadow: none;
  transition: all ease 250ms;
}
._view_raised__Tl-QG._view_checked__1zgJJ > label[class*="control"] > span[class*="indicator"],
._view_raised__Tl-QG._view_checked__1zgJJ:hover > label[class*="control"] > span[class*="indicator"] {
  background-color: var(--color5);
  transition: all ease 150ms;
}

._view_raised__Tl-QG._view_checked__1zgJJ input:checked ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
  background-color: var(--color70);
  transition: transform ease 250ms, background-color ease 250ms;
}
._view_raised__Tl-QG:hover input:checked ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
}
._view_raised__Tl-QG input:not(:checked) ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
  background-color: var(--color70);
  transform: scale(0);
  transition: transform ease 150ms;
}
/*Disabled*/
._view_raised__Tl-QG._view_disabled__2QzDo > label[class*="control"] > div {
  color: var(--color40);
}
._view_raised__Tl-QG._view_checked__1zgJJ._view_disabled__2QzDo,
._view_raised__Tl-QG._view_disabled__2QzDo:hover,
._view_raised__Tl-QG._view_checked__1zgJJ._view_disabled__2QzDo > label[class*="control"] > span[class*="indicator"] {
  background-color: var(--color0);
  box-shadow: var(--shadow2dp);
}
._view_raised__Tl-QG._view_disabled__2QzDo._view_checked__1zgJJ > label[class*="control"] > span[class*="indicator"] {
  background-color: transparent;
  background-color: initial;
  box-shadow: none;
}
/*helper*/
._view_raised__Tl-QG ._view_helperText__1hKcd,
._view_raised__Tl-QG._view_disabled__2QzDo ._view_helperText__1hKcd {
  color: var(--color60);
}
/*-----------*/

/*OUTLINED*/
._view_outlined__2r45G {
  color: var(--color100);
  background: var(--white);
  box-shadow: 0 0 0 0.0625rem var(--color20);
}
._view_outlined__2r45G:hover {
  box-shadow: 0 0 0 0.0625rem var(--color40);
  transition: all ease 250ms;
}
._view_outlined__2r45G._view_checked__1zgJJ {
  box-shadow: 0 0 0 0.125rem var(--color40);
  background: var(--white);
  transition: all ease 250ms;
}
._view_outlined__2r45G > label[class*="control"] span[class*="indicator"] {
  background: var(--white);
  background-image: none;
  box-shadow: none;
  border: 2px solid var(--color70);
}
._view_outlined__2r45G:hover:not(._view_disabled__2QzDo)
  > label[class*="control"]
  > span[class*="indicator"] {
  background-color: var(--white);
  box-shadow: none;
  transition: all ease 250ms;
}
._view_outlined__2r45G._view_checked__1zgJJ > label[class*="control"] > span[class*="indicator"] {
  background-color: var(--white);
  background: var(--white);
  box-shadow: none;
  transition: all ease 250ms;
}

._view_outlined__2r45G._view_checked__1zgJJ input:checked ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
  background-color: var(--color70);
  transition: transform ease 250ms, background-color ease 250ms;
}
._view_outlined__2r45G:hover input:checked ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
}
._view_outlined__2r45G input:not(:checked) ~ span[class*="indicator"]::before {
  background-image: radial-gradient(
    var(--color70),
    var(--color70) 28%,
    transparent 32%
  );
  background-color: var(--color70);
  transform: scale(0);
  transition: transform ease 150ms;
}
/*Disabled*/
._view_outlined__2r45G._view_disabled__2QzDo > label[class*="control"] > div {
  color: var(--color40);
}
._view_outlined__2r45G._view_checked__1zgJJ._view_disabled__2QzDo,
._view_outlined__2r45G._view_disabled__2QzDo:hover,
._view_outlined__2r45G._view_checked__1zgJJ._view_disabled__2QzDo
  > label[class*="control"]
  > span[class*="indicator"] {
  box-shadow: 0 0 0 0.0625rem var(--color10);
}
._view_outlined__2r45G._view_disabled__2QzDo._view_checked__1zgJJ
  > label[class*="control"]
  > span[class*="indicator"] {
  background-color: transparent;
  background-color: initial;
  box-shadow: none;
}
/*helper*/
._view_outlined__2r45G ._view_helperText__1hKcd,
._view_outlined__2r45G._view_disabled__2QzDo ._view_helperText__1hKcd {
  color: var(--color60);
}
/*-----------*/

/*HELPER TEXT*/
/*.helper div {
  color: var(--grey50);
}
.helper.disabled div {
  color: var(--grey40);
}*/

