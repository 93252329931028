/* COLORS*/
:root {
  --color0: var(--grey0);
  --color5: var(--grey5);
  --color10: var(--grey10);
  --color20: var(--grey20);
  --color30: var(--grey30);
  --color40: var(--grey40);
  --color50: var(--grey50);
  --color60: var(--grey60);
  --color70: var(--grey70);
  --color80: var(--grey80);
  --color90: var(--grey90);
  --color100: var(--grey100);

  --shadow2dp: var(--greyShadow2dp);
  --shadow4dp: var(--greyShadow4dp);
  --shadow8dp: var(--greyShadow8dp);
  --shadow16dp: var(--greyShadow16dp);
  --shadow24dp: var(--greyShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGrey);
  --gradient-reverse: var(--gradGreyRev);
  /*-----------------*/

  /*TABLE*/
  --flat-def-bg-table-hover: var(--color5);
  --flat-def-color-table: var(--color50);
  --flat-def-border-table: var(--color10);
  --flat-def-border2-table: var(--color50);
  --flat-def-borderlast-table: var(--color40);
  --flat-def-color-table-pag: var(--color60);
  --flat-def-color-checkbox: var(--color70);

  --filled-def-bg-table: var(--color40);
  --filled-def-bg-table-hover: var(--color50);
  --filled-def-bg-head-table: var(--color70);
  --filled-def-border-table: var(--color30);
  --filled-def-border2-table: var(--color10);
  --filled-def-head-table-sort: var(--color60);
  --filled-def-color-checkbox: var(--color20);

  --smooth-def-bg-head-table: var(--color10);
  --smooth-def-bg-table: var(--color0);
  --smooth-def-border-table: var(--color10);
  --smooth-def-border2-table: var(--color50);
  --smooth-def-color-table: var(--color50);
  --smooth-def-color-table-pag: var(--color60);

  --raised-def-border-table: var(--color10);
  --raised-def-border2-table: var(--color50);
  --raised-def-prim-color-table: var(--color50);
  /*-------------------*/
}

.select {
  /*TABLE*/
  --flat-bg-table-hover: var(--color5);
  --flat-color-table: var(--color50);
  --flat-border-table: var(--color10);
  --flat-border2-table: var(--color50);
  --flat-borderlast-table: var(--color40);
  --flat-color-table-pag: var(--color60);
  --flat-color-checkbox: var(--color70);

  --filled-bg-table: var(--color40);
  --filled-bg-table-hover: var(--color50);
  --filled-bg-head-table: var(--color70);
  --filled-border-table: var(--color30);
  --filled-border2-table: var(--color10);
  --filled-head-table-sort: var(--color60);
  --filled-color-checkbox: var(--color20);

  --smooth-bg-head-table: var(--color10);
  --smooth-bg-table: var(--color0);
  --smooth-border-table: var(--color10);
  --smooth-border2-table: var(--color50);
  --smooth-color-table: var(--color50);
  --smooth-color-table-pag: var(--color60);

  --raised-border-table: var(--color10);
  --raised-border2-table: var(--color50);
  --raised-prim-color-table: var(--color50);
  /*-------------------*/
}
.primary {
  --color0: var(--blue0);
  --color5: var(--blue5);
  --color10: var(--blue10);
  --color20: var(--blue20);
  --color30: var(--blue30);
  --color40: var(--blue40);
  --color50: var(--blue50);
  --color60: var(--blue60);
  --color70: var(--blue70);
  --color80: var(--blue80);
  --color90: var(--blue90);
  --color100: var(--blue100);

  --shadow2dp: var(--blueShadow2dp);
  --shadow4dp: var(--blueShadow4dp);
  --shadow8dp: var(--blueShadow8dp);
  --shadow16dp: var(--blueShadow16dp);
  --shadow24dp: var(--blueShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradBlue);
  --gradient-reverse: var(--gradBlueRev);
  /*-----*/
  composes: select;
}

.danger {
  --color0: var(--red0);
  --color5: var(--red5);
  --color10: var(--red10);
  --color20: var(--red20);
  --color30: var(--red30);
  --color40: var(--red40);
  --color50: var(--red50);
  --color60: var(--red60);
  --color70: var(--red70);
  --color80: var(--red80);
  --color90: var(--red90);
  --color100: var(--red100);

  --shadow2dp: var(--redShadow2dp);
  --shadow4dp: var(--redShadow4dp);
  --shadow8dp: var(--redShadow8dp);
  --shadow16dp: var(--redShadow16dp);
  --shadow24dp: var(--redShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradRed);
  --gradient-reverse: var(--gradRedRev);
  /*-----*/
  composes: select;
}
.success {
  --color0: var(--green0);
  --color5: var(--green5);
  --color10: var(--green10);
  --color20: var(--green20);
  --color30: var(--green30);
  --color40: var(--green40);
  --color50: var(--green50);
  --color60: var(--green60);
  --color70: var(--green70);
  --color80: var(--green80);
  --color90: var(--green90);
  --color100: var(--green100);

  --shadow2dp: var(--greenShadow2dp);
  --shadow4dp: var(--greenShadow4dp);
  --shadow8dp: var(--greenShadow8dp);
  --shadow16dp: var(--greenShadow16dp);
  --shadow24dp: var(--greenShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGreen);
  --gradient-reverse: var(--gradGreenRev);
  /*-----------------*/
  composes: select;
}
.warning {
  --color0: var(--yellow0);
  --color5: var(--yellow5);
  --color10: var(--yellow10);
  --color20: var(--yellow20);
  --color30: var(--yellow30);
  --color40: var(--yellow40);
  --color50: var(--yellow50);
  --color60: var(--yellow60);
  --color70: var(--yellow70);
  --color80: var(--yellow80);
  --color90: var(--yellow90);
  --color100: var(--yellow100);

  --shadow2dp: var(--yellowShadow2dp);
  --shadow4dp: var(--yellowShadow4dp);
  --shadow8dp: var(--yellowShadow8dp);
  --shadow16dp: var(--yellowShadow16dp);
  --shadow24dp: var(--yellowShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradYellow);
  --gradient-reverse: var(--gradYellowRev);
  /*-----------------*/
  composes: select;
}
.default {
  --color0: var(--grey0);
  --color5: var(--grey5);
  --color10: var(--grey10);
  --color20: var(--grey20);
  --color30: var(--grey30);
  --color40: var(--grey40);
  --color50: var(--grey50);
  --color60: var(--grey60);
  --color70: var(--grey70);
  --color80: var(--grey80);
  --color90: var(--grey90);
  --color100: var(--grey100);

  --shadow2dp: var(--greyShadow2dp);
  --shadow4dp: var(--greyShadow4dp);
  --shadow8dp: var(--greyShadow8dp);
  --shadow16dp: var(--greyShadow16dp);
  --shadow24dp: var(--greyShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradGrey);
  --gradient-reverse: var(--gradGreyRev);
  /*-----------------*/
  composes: select;
}

.primary_alt {
  --color0: var(--indigo0);
  --color5: var(--indigo5);
  --color10: var(--indigo10);
  --color20: var(--indigo20);
  --color30: var(--indigo30);
  --color40: var(--indigo40);
  --color50: var(--indigo50);
  --color60: var(--indigo60);
  --color70: var(--indigo70);
  --color80: var(--indigo80);
  --color90: var(--indigo90);
  --color100: var(--indigo100);

  --shadow2dp: var(--indigoShadow2dp);
  --shadow4dp: var(--indigoShadow4dp);
  --shadow8dp: var(--indigoShadow8dp);
  --shadow16dp: var(--indigoShadow16dp);
  --shadow24dp: var(--indigoShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradIndigo);
  --gradient-reverse: var(--gradIndigoRev);
  /*-----*/
  composes: select;
}

.danger_alt {
  --color0: var(--pink0);
  --color5: var(--pink5);
  --color10: var(--pink10);
  --color20: var(--pink20);
  --color30: var(--pink30);
  --color40: var(--pink40);
  --color50: var(--pink50);
  --color60: var(--pink60);
  --color70: var(--pink70);
  --color80: var(--pink80);
  --color90: var(--pink90);
  --color100: var(--pink100);

  --shadow2dp: var(--pinkShadow2dp);
  --shadow4dp: var(--pinkShadow4dp);
  --shadow8dp: var(--pinkShadow8dp);
  --shadow16dp: var(--pinkShadow16dp);
  --shadow24dp: var(--pinkShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradPink);
  --gradient-reverse: var(--gradPinkRev);
  /*-----*/
  composes: select;
}
.success_alt {
  --color0: var(--teal0);
  --color5: var(--teal5);
  --color10: var(--teal10);
  --color20: var(--teal20);
  --color30: var(--teal30);
  --color40: var(--teal40);
  --color50: var(--teal50);
  --color60: var(--teal60);
  --color70: var(--teal70);
  --color80: var(--teal80);
  --color90: var(--teal90);
  --color100: var(--teal100);

  --shadow2dp: var(--tealShadow2dp);
  --shadow4dp: var(--tealShadow4dp);
  --shadow8dp: var(--tealShadow8dp);
  --shadow16dp: var(--tealShadow16dp);
  --shadow24dp: var(--tealShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradTeal);
  --gradient-reverse: var(--gradTealRev);
  /*-----------------*/
  composes: select;
}
.warning_alt {
  --color0: var(--orange0);
  --color5: var(--orange5);
  --color10: var(--orange10);
  --color20: var(--orange20);
  --color30: var(--orange30);
  --color40: var(--orange40);
  --color50: var(--orange50);
  --color60: var(--orange60);
  --color70: var(--orange70);
  --color80: var(--orange80);
  --color90: var(--orange90);
  --color100: var(--orange100);

  --shadow2dp: var(--orangeShadow2dp);
  --shadow4dp: var(--orangeShadow4dp);
  --shadow8dp: var(--orangeShadow8dp);
  --shadow16dp: var(--orangeShadow16dp);
  --shadow24dp: var(--orangeShadow24dp);

  /*GRADIENTS*/
  --gradient: var(--gradOrange);
  --gradient-reverse: var(--gradOrangeRev);
  /*-----------------*/
  composes: select;
}

/* END COLORS*/
