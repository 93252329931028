@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Inter';
  src: url('./assets/fonts/Inter-Light.ttf') format('truetype');
  font-weight: lighter;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Merriweather';
  src: url('./assets/fonts/Merriweather-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@font-face {
  font-family: 'Merriweather';
  src: url('./assets/fonts/Merriweather-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
